<template>
    <div id="rotate-blocker" class="abs100">
        <div class="bgimg bg" :style="{'background-image': 'url(' + require('@/assets/images/rotate/bg.jpg') + ')'}"></div>
        <div class="rotate abs100">
            <img :src="require('@/assets/images/rotate/rotate.png')" />
        </div>
        <div class="bgimg noise" :style="{'background-image': 'url(' + require('@/assets/images/rotate/noise.png') + ')'}"></div>
        <div class="top abs100">
            <img :src="require('@/assets/images/rotate/top.png')" />
        </div>
        <div class="bottom abs100">
            <img :src="require('@/assets/images/rotate/bottom.png')" />
        </div>
    </div>
</template>

<script>
export default {
    name: "RotateBlocker"
}
</script>
