<template>
    <div v-show="gh.showSection('losttools')" id="losttools" :class="['subsection', gh.subsectionClasses('losttools')]">
        <!-- BGS -->
        <div class="show-desktop scaler" data-scaler-w="1920" data-scaler-h="1080">
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/bg-tools.jpg') + ')'}"></div>
            <div class="bgimg">
                <video :Zposter="require('@/assets/video/bg-tools.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/bg-tools.mp4')">
                </video>
            </div>
            <div v-show="isRevealing" class="bgimg">
                <video id="losttools-video" :Zposter="require('@/assets/video/intro-tools.jpg')" muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/intro-tools.mp4')">
                </video>
            </div>
        </div>
        <div class="show-mobile scaler" data-scaler-w="620" data-scaler-h="1080">
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/mobile-bg-tools.jpg') + ')'}"></div>
            <div class="bgimg">
                <video :Zposter="require('@/assets/video/mobile-bg-tools.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/mobile-bg-tools.mp4')">
                </video>
            </div>
            <div v-show="isRevealing" class="bgimg">
                <video id="mobile-losttools-video" :Zposter="require('@/assets/video/mobile-intro-tools.jpg')" muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/mobile-intro-tools.mp4')">
                </video>
            </div>
        </div>



        <div class="content">
            <div :class="(isMobileWidth) ? 'was-scaler' : 'scaler-contained'" data-scaler-w="2003" data-scaler-h="2160" data-extra-padding-x="0" data-extra-padding-y="0">
                <div class="items">

                    <div class="items-core">
                        <div class="items-core-inner">
                            <div class="item item-message">
                                <div class="c-outer">
                                    <div class="c-edge">
                                        <div class="icon accent-left">
                                            <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                                        </div>
                                    </div>
                                    <div class="c-center">
                                        <div class="txt">Who Has The</div>
                                        <div class="txt current-tool">
                                            <span v-if="this.answersCorrect===0">Dream Stone</span>
                                            <span v-if="this.answersCorrect===1">Sand</span>
                                            <span v-if="this.answersCorrect===2">Helm</span>
                                        </div>
                                    </div>
                                    <div class="c-edge">
                                        <div class="icon accent-right">
                                            <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="item item-form">
                                <input maxlength="30"  v-on:keyup.enter="inputEnter" v-model="inputValue" type="text" id="fname" name="fname" placeholder="Enter Your Answer">
                                <div  @click="tryGuess" :class="['form-submit', (isInputValid) ? 'enabled' : 'disabled']">
                                    <div class="bg"></div>
                                    <span>Submit</span>
                                </div>
                            </div>

                            <!-- AWARDS -->
                            <div class="slots-holder">
                                <div :class="['item item-slot dream-stone', (this.answersCorrect>0) ? 'correct' : '']">
                                    <!-- <div v-bind:class="{ disabled:!item.correct }" class="txt-icon" > -->
                                    <div class="txt-icon">
                                        <div class="txt">Dream Stone</div>
                                        <div class="icon">
                                            <!-- <inline-svg :src="require('@/assets/images/losttools/tools-dream-stone.svg')"/> -->
                                            <img :src="require('@/assets/images/losttools/dream-stone.png')" alt="">
                                        </div>
                                        <div class="txt answer">John Dee</div>
                                    </div>
                                </div>
                                <div :class="['item item-slot sand', (this.answersCorrect>1) ? 'correct' : '']">
                                    <div class="txt-icon">
                                        <div class="txt">Sand</div>
                                        <div class="icon">
                                            <!-- <inline-svg :src="require('@/assets/images/losttools/tools-sand.svg')"/> -->
                                            <img :src="require('@/assets/images/losttools/sand.png')" alt="">
                                        </div>
                                        <div class="txt answer">Johanna Constantine</div>
                                    </div>
                                </div>
                                <div :class="['item item-slot helm', (this.answersCorrect>2) ? 'correct' : '']">
                                    <div class="txt-icon">
                                        <div class="txt">Helm</div>
                                        <div class="icon">
                                            <!-- <inline-svg :src="require('@/assets/images/losttools/tools-helm.svg')"/> -->
                                            <img :src="require('@/assets/images/losttools/helm.png')" alt="">
                                        </div>
                                        <div class="txt answer">Lucifer</div>
                                    </div>
                                </div>
                            </div>

                            <div class="show-mobile">
                                <div class="message">Explore the Characters</div>
                                <div class="message">To Find the Clues</div>
                                <img class="line-arrow" :src="require('@/assets/images/losttools/scroll-down-mobile.png')"/>
                            </div>
                        </div>
                    </div>


                    <!-- ICONS -->
                    <div class="items-icons">
                        <div class="smokebg">
                            <div class="show-mobile bgimg" :style="{'background-image': 'url(' + require(`@/assets/video/mobile-bg-smokey.jpg`) + ')'}"></div>
                            <div class="show-mobile bgimg">
                                <video :Zposter="require('@/assets/video/mobile-bg-smokey.jpg')" autoplay loop muted playsinline webkit-playsinline>
                                    <source :src="require('@/assets/video/mobile-bg-smokey.mp4')">
                                </video>
                            </div>
                        </div>
                        <div class="show-mobile">
                            <img class="line-arrow up" :src="require('@/assets/images/losttools/scroll-down-mobile.png')"/>
                            <div class="message">Enter Your Answer</div>

                        </div>

                        <template v-for="(item, index) in allNames" :key="`losttools-names-${index}`">
                            <div v-if="!item.hideInLostTools"  :class="`scrolly-item-hook item item-losttools-${item.link}`">
                                <div class="bg"></div>
                                <div @mouseenter="characterOver(item.name)" @click="pickCharacter(item.link)"  class="txt-icon">
                                    <div class="txt" v-html="item.name"></div>
                                    <div class="icon">
                                        <img class="a1" v-if="item.triangleTop" :src="require('@/assets/images/losttools/ornamentation-triangle-top.svg')"/>
                                        <img class="a2" v-else-if="item.twoline" :src="require('@/assets/images/losttools/ornamentation-top-and-bottom.svg')"/>
                                        <img class="a3" v-else :src="require('@/assets/images/losttools/ornamentation-top-and-bottom-one-line.svg')"/>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="mobile-padder"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showAnswer && this.answersCorrect<2" class="answer-state abs100" :style="{'background-image': 'url(' + require(`@/assets/video/mobile-bg-smokey.jpg`) + ')'}">
            <div class="show-mobile bgimg"
                 :style="{'background-image': 'url(' + require(`@/assets/images/character-details/detail/${currentGuess.link}/mobile-hero.jpg`) + ')'}">
            </div>
            <div class="show-mobile bgimg">
                <video :Zposter="require(`@/assets/images/character-details/detail/${currentGuess.link}/mobile-hero.jpg`)" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require(`@/assets/images/character-details/detail/${currentGuess.link}/mobile-hero.mp4`)">
                </video>
            </div>

            <div class="show-desktop bgimg"
                 :style="{'background-image': 'url(' + require(`@/assets/images/character-details/detail/${currentGuess.link}/hero.jpg`) + ')'}">
            </div>
            <div class="show-desktop bgimg">
                <video :Zposter="require(`@/assets/images/character-details/detail/${currentGuess.link}/hero.jpg`)" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require(`@/assets/images/character-details/detail/${currentGuess.link}/hero.mp4`)">
                </video>
            </div>
            <div :class="`copy ${blurbSide}`">
                <div class="chose">You Have Chosen</div>
                <div class="name">
                    <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                    <span v-html="currentGuess.name"></span>
                    <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                </div>


                <div v-if="this.answersCorrect===0" class="tool">Dream Stone</div>
                <div v-if="this.answersCorrect===0" class="tool-icon">
                    <!-- <inline-svg :src="require('@/assets/images/losttools/tools-dream-stone.svg')"/> -->
                    <img :src="require('@/assets/images/losttools/dream-stone.png')" alt="">
                </div>

                <div v-if="this.answersCorrect===1" class="tool">Sand</div>
                <div v-if="this.answersCorrect===1" class="tool-icon">
                    <!-- <inline-svg :src="require('@/assets/images/losttools/tools-sand.svg')"/> -->
                    <img :src="require('@/assets/images/losttools/sand.png')" alt="">
                </div>

                <div v-if="this.answersCorrect===2" class="tool">Helm</div>
                <div v-if="this.answersCorrect===2" class="tool-icon">
                    <!-- <inline-svg :src="require('@/assets/images/losttools/tools-helm.svg')"/> -->
                    <img :src="require('@/assets/images/losttools/helm.png')" alt="">
                </div>


                <div class="message">
                    <span v-if="showAnswer==='correct'">Nice Work!</span>
                    <span v-else-if="showAnswer==='incorrect'">Incorrect Answer</span>
                    <span v-else>Unknow Errorr</span>
                </div>
                <div v-if="showAnswer==='incorrect'" @click="closeGuessScreen" class="cta">
                    <div class="ctabg">
                        <!-- <inline-svg :src="require('@/assets/images/global/signup-frame.svg')" /> -->
                        <img :src="require('@/assets/images/losttools/cta.png')"  alt="">
                    </div>
                    <div class="ctaover"><span>TRY AGAIN</span></div>
                </div>
                <div v-if="showAnswer==='correct'" @click="closeGuessScreen" class="cta">
                    <div class="ctabg">
                        <img :src="require('@/assets/images/losttools/cta.png')"  alt="">
                    </div>
                    <div class="ctaover">
                        <span v-if="this.answersCorrect===0">Find Dream's Sand</span>
                        <span v-if="this.answersCorrect===1">Find Dream's Helm</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showAnswer && this.answersCorrect>=2" class="answer-state final-state abs100">
            <div class="bgimg"
                 :style="{'background-image': 'url(' + require('@/assets/images/losttools/bg-losttools.jpg') + ')'}">

            </div>
            <div class="copy">

                <div class="chose">Congratulations!</div>
                <div class="name">
                    <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                    YOU FOUND DREAM'S LOST TOOLS
                    <inline-svg :src="require('@/assets/images/losttools/accent-left.svg')"/>
                </div>

                <div class="tools">
                    <div class="tool-icon">
                        <div class="tool">Dream Stone</div>
                        <!-- <inline-svg :src="require('@/assets/images/losttools/tools-dream-stone.svg')"/> -->
                        <img :src="require('@/assets/images/losttools/dream-stone.png')" alt="">
                        <div class="message">JOHN DEE</div>
                    </div>
                    <div class="tool-icon">
                        <div class="tool">Sand</div>
                        <!-- <inline-svg :src="require('@/assets/images/losttools/tools-sand.svg')"/> -->
                        <img :src="require('@/assets/images/losttools/sand.png')" alt="">
                        <div class="message">JOHANNA CONSTATNINE</div>
                    </div>
                    <div class="tool-icon">
                        <div class="tool">Helm</div>
                        <!-- <inline-svg :src="require('@/assets/images/losttools/tools-helm.svg')"/> -->
                        <img :src="require('@/assets/images/losttools/helm.png')" alt="">
                        <div class="message">LUCIFER</div>
                    </div>

                </div>





                <div @click="downloadItem" class="cta">
                    <div class="ctabg">
                        <img :src="require('@/assets/images/losttools/cta.png')"  alt="">
                    </div>
                    <div class="ctaover">
                        <span>SHARE</span>
                    </div>
                </div>


            </div>
        </div>
        <BackButton v-show="showBackButton" target="wakingdreaming" copy="wakingdreaming"></BackButton>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import scrollyRolly from '@/helpers/scrollyRolly'
import {mapState} from 'vuex'
import gsap, {Power3} from '@/assets/js/gsap/all'
import BackButton from "@/components/blocks/BackButton"
import axios from 'axios'
axios.defaults.withCredentials = true
export default {
    name: "LostTools",
    components: {
        BackButton
    },
    data () {
        return {
            document: document,
            window: window,
            gh: gh,

            isRevealing: false,

            //form
            characters: [],
            allNames: [],
            answersCorrect: 0,
            showAnswer:null,
            blurbSide: 'right',
            currentGuess: {},
            inputValue: '',

            vid: null

        }
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            previousSubsection: state => state.previousSubsection,
            isMobileWidth: state => state.isMobileWidth,

        }),

        isInputValid() {
            let returnObj = this.getInputObject()
            return (returnObj)
        },
        showBackButton() {
            let ret = true
            if (this.showAnswer && this.answersCorrect<2) {//if answer popup, but not final screen
               ret = false
            }
            return ret
        }
    },
    mounted () {
        this.characters = this.window.config.json.global.site.characters
        this.allNames = this.window.config.json.global.site.libraryNames['dreaming']
        this.allNames = this.allNames.concat(this.window.config.json.global.site.libraryNames['waking'])
        this.allNames = this.allNames.concat(this.window.config.json.global.site.libraryNames['endless'])
        this.allNames = this.allNames.concat(this.window.config.json.global.site.libraryNames['unlisted'])
    },
    watch: {
        currentSubsectionNew (val) {
            if (val === 'losttools') {
                if (this.window.config.fastLoadMode) {
                    setTimeout(this.reinit,10)
                } else {
                    let skipIntroVideo = false
                    if (String(this.previousSubsection).includes('library-character-detail')) {
                        skipIntroVideo = true
                    }

                    if (skipIntroVideo) {
                        setTimeout(this.reinit,10)
                    } else {
                        this.reveal()
                    }
                }
            }
        },
        isMobileWidth() {
            if (this.currentSubsection === 'losttools') {
                setTimeout(this.reinit,10)
            }
        }
    },
    methods: {
        reinit() {
            //undo any scaler logic from desktop
            gsap.set('#losttools .was-scaler', {
                scale: 1,
                width: '100%',
                height: '100%',
                x: 0,
                y: 0
            })

            //add scrolly - mobile only
            let sel = '.scrolly-item-hook'
            scrollyRolly.destroy(sel)
            if (this.isMobileWidth) {
                scrollyRolly.init(sel, null, this)
            }

            //now reveal
            this.reveal3()

        },
        reveal () {
            gsap.set('#losttools .content', {  opacity:0 })
            this.$store.commit('setDoingVideoTransition', true)
            this.isRevealing = true
            setTimeout(this.reveal2, 10)
        },
        reveal2 () {
            // play video then reveal in content when video is done
            if (this.isMobileWidth) {
                this.vid = document.getElementById("mobile-losttools-video")
            } else {
                this.vid = document.getElementById("losttools-video")
            }
            if (this.vid) {
                this.vid.pause()
                this.vid.addEventListener("ended", this.reveal3)
                this.vid.volume = 0
                this.vid.muted = true
                this.vid.currentTime = 0
                this.vid.play()
            }
        },

        reveal3 () {
            let tl = gsap.timeline({
                onComplete: this.revealComplete
            })
            tl.fromTo('#losttools .content', 0.5,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    ease: Power3.easeInOut
                },
                0
            )
            this.$store.commit('setDoingVideoTransition', false)


        },
        revealComplete () {
            this.isRevealing = false
            if (this.vid) {
                this.vid.removeEventListener("ended", this.reveal3)
                this.vid.currentTime = 0
                this.vid.pause()
            }

            gh.newSectionFinish()
        },


        //TOOLS GAME LOGIC
        pickCharacter (character) {
            gh.newSectionStart('library-character-detail-' + character)
        },
        inputEnter () {
            this.tryGuess()
        },
        characterOver (characterName) {
            this.inputValue = characterName.replace('<br>',' ')
        },
        getInputObject() {
            let returnObj = null
            let guess = String(this.inputValue).trim().toLowerCase().replace('<br>',' ')
            for (let i=0;i<this.allNames.length;i++) {
                let obj = this.allNames[i]
                let name = String(obj.name).toLowerCase()
                if (guess.indexOf(obj.link)>-1 || guess.indexOf(name)>-1) {
                    returnObj = obj
                }
            }
            return returnObj
        },
        tryGuess() {
            let answer = 'john'
            if (this.answersCorrect===2) {
                answer = 'lucifer'
            } else if (this.answersCorrect===1) {
                answer = 'constantine'
            }


            let returnObj = this.getInputObject()
            if (returnObj) {
                gh.pingAnalyticsCustom('lost-tools', 'Guess Question ' + (this.answersCorrect+1), returnObj.link)
                if (returnObj.link === answer) {
                    this.setCorrect(returnObj)
                } else {
                    this.setIncorrect(returnObj)
                }
            }
        },
        setCorrect(obj) {
            this.currentGuess = obj
            this.setBlurbSide()
            this.showAnswer = 'correct'
            if (this.answersCorrect>=2) {
                gh.pingAnalyticsCustom('lost-tools', 'Completed')
            }
        },
        setIncorrect(obj) {
            this.currentGuess = obj
            this.setBlurbSide()
            this.showAnswer = 'incorrect'
        },
        setBlurbSide() {
            for (let i=0;i<this.characters.length;i++) {
                if (this.characters[i].name===this.currentGuess.link) {
                    this.blurbSide=this.characters[i].blurbSide
                }
            }
            if (!this.blurbSide) {
                this.blurbSide='right'
            }


        },
        closeGuessScreen() {
            if (this.showAnswer==='correct') {
                this.answersCorrect++
                if (this.answersCorrect>3) {
                    this.answersCorrect=3
                }
            }
            this.showAnswer = ''
            this.currentGuess = {}
        },

        downloadItem () {
            let url = '/assets/sandman-lost-tools-completed.png'
            let label = 'sandman-lost-tools-completed.png'
            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/png' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)

            gh.pingAnalyticsCustom('lost-tools', 'Downloads Share')
        }

    } //methods
}
</script>
