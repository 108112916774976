<template>
    <div id="raven-guide" :class="(ravenShown) ?'visible' : 'hidden'">
        <div class="graphicals">
            <div class="diamond-small">
                <div class="bg"></div>
            </div>
            <div class="diamond-big">
                <div class="bg"></div>
            </div>
            <div class="bird">
                <img :src="require('@/assets/images/raven-guide/raven.png')" />
            </div>
        </div>
        <div class="copy">
            <div class="arrow"></div>
            <div id="raven-text1" class="txt-outer">
                <div id="raven-text2" :class="['txt', (doTransitionalTextHide) ? 'opacity0' : 'opacity1']" ></div>
            </div>
            <!-- used to invisibly get height of new text to make smoother box fades -->
            <div id="raven-text-stager1" class="txt-outer opacity0">
                <div id="raven-text-stager2" :class="['txt opacity0']" ></div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { SplitText } from '@/assets/js/gsap/SplitText'
gsap.registerPlugin(SplitText)
export default {
    name: "RavenGuide",
    data() {
        return {
            window:window,
            document:document,
            isRevealedOnce: false,
            didIntroMessage: false,

            messages:[],
            ravenMessageOG: null,
            ravenMessage: null,

            ravenShown: false,

            doTransitionalTextHide: false,
        }
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            doingVideoTransition: state => state.doingVideoTransition,
            wakingDreaming: state => state.wakingDreaming,
            overlayObject: state => state.overlayObject,
            currentLibraryNamesContent: state => state.currentLibraryNamesContent,
        }),
        showRaven() {
            //if not in transition, and has a message, and not in overlay
            return (!this.doingVideoTransition && this.ravenMessage && !this.overlayObject)
        }
    },
    mounted() {
        this.messages = window.config.json.global.site.ravenMessages
    },
    watch: {
        currentSubsection(val) {
            if (val) {
                this.messageChanger()
            }
        },
        wakingDreaming() {
            if (this.wakingDreaming) {
                this.messageChanger()
            }
        },
        showRaven(val) {
            if (val) {
                this.reveal()
            } else {
                this.unreveal()
            }
        }
    },
    methods: {
        reveal() {
            let tl = gsap.timeline({})
            tl.fromTo('#raven-guide .diamond-big .bg', 0.5,
                {
                    scale:0,
                },
                {
                    scale:1,
                    ease: Power3.easeInOut
                },
                0
            )
            tl.fromTo('#raven-guide .diamond-small .bg', 0.5,
                {
                    scale:0
                },
                {
                    scale:1,
                    ease: Power3.easeInOut
                },
                0.2
            )
            tl.fromTo('#raven-guide .bird', 0.5,
                {
                    opacity:0,
                    scale:0.8,
                    y:20,
                },
                {
                    opacity:1,
                    scale:1,
                    y:0,
                    ease: Power3.easeInOut
                },
                0.4
            )
            tl.fromTo('#raven-guide .copy', 0.5,
                {
                    opacity:0,
                    x:-20,
                },
                {
                    opacity:1,
                    x:0,
                    ease: Power3.easeInOut
                },
                0.6
            )
            this.ravenShown = true

        },
        unreveal() {
            let tl = gsap.timeline({
                onComplete:this.unreveal2
            })
            tl.to('#raven-guide .copy', 0.5,
                {
                    opacity:0,
                    x:-20,
                    ease: Power3.easeInOut
                },
                0
            )
            tl.to('#raven-guide .bird', 0.5,
                {
                    opacity:0,
                    y:20,
                    scale:0.8,
                    ease: Power3.easeInOut
                },
                0
            )
            tl.to('#raven-guide .diamond-big .bg', 0.5,
                {
                    scale:0,
                    ease: Power3.easeInOut
                },
                0.2
            )
            tl.to('#raven-guide .diamond-small .bg', 0.5,
                {
                    scale:0,
                    ease: Power3.easeInOut
                },
                0.4
            )
        },
        unreveal2() {
            this.ravenShown = false
        },
        messageChanger() {
            if (!this.didIntroMessage) {
                let obj = this.messages[0]
                this.messageChanger2(obj.message)
            } else {
                let currentRavenMessage = this.ravenMessageOG
                let msg = null
                for (let i = 0;i<this.messages.length;i++) {
                    let obj = this.messages[i]

                    let checkAgainst = this.currentSubsection
                    if (this.currentSubsection === 'wakingdreaming') {
                        checkAgainst = this.wakingDreaming
                    }
                    if (obj.type === checkAgainst) {
                        //special override for library-character-names
                        if (this.currentSubsection === 'library-character-names') {
                            msg = obj['message-'+this.currentLibraryNamesContent]
                        } else {
                            msg = obj.message
                        }
                    }
                }

                if (currentRavenMessage === null || currentRavenMessage !== msg) {
                    this.messageChanger2(msg)
                }
            }
        },
        messageChanger2(msg) {
            //set staging
            const elem = this.document.getElementById('raven-text-stager2')
            elem.innerHTML = msg

            new SplitText('#raven-text-stager2', {
                type: 'words,chars', //words, chars
                wordsClass: 'words-split',
                charsClass: 'txt-split',
            });

            let t = this
            setTimeout(function(msg) {
                t.messageChanger2b(msg)
            }.bind(t, msg),10)
        },
        messageChanger2b(msg) {
            let tl = gsap.timeline({
                onComplete: this.messageChanger3,
                onCompleteParams: [msg]
            })
            tl.fromTo('#raven-text2 .txt-split',
                {
                    opacity: 1,
                    x:0,
                },
                {
                    duration: 0.35,
                    opacity: 0,
                    x:-10,
                    ease: Power3.easeInOut,
                    stagger: 0.01,
                },
                0
            )

            const elem = this.document.getElementById('raven-text-stager2')
            let h = elem.clientHeight + 1
            if (h<=31) h=0
            tl.to('#raven-text1',
                {
                    duration: 0.35,
                    height: h,
                    ease: Power3.easeInOut,
                },
                0.15
            )
        },
        messageChanger3(msg) {
            this.doTransitionalTextHide = true
            this.ravenMessage = msg
            this.ravenMessageOG = msg

            const tempText = this.document.getElementById('raven-text2')
            if(tempText) {
                tempText.innerHTML = msg
            }
            setTimeout(this.messageChanger4,50)
        },
        messageChanger4() {
            new SplitText('#raven-text2', {
                type: 'words,chars', //words, chars
                wordsClass: 'words-split',
                charsClass: 'txt-split',
            });


            let tl = gsap.timeline({})
            tl.fromTo('#raven-text2 .txt-split',
                {
                    opacity: 0,
                    x:-10,
                },
                {
                    duration: 0.35,
                    x:0,
                    opacity: 1,
                    ease: Power3.easeInOut,
                    stagger: 0.01,
                },
                0
            )
            this.doTransitionalTextHide = false

            if (!this.didIntroMessage) {
                this.didIntroMessage = true
                setTimeout(this.messageChanger5, 2000)
            }
        },
        messageChanger5() {
            this.messageChanger()
        }
    }
}
</script>
