<template>
    <div class="episode-slide-show abs100">
        <div class="items">
            <!-- ADD ITEMS HERE. THIS GETS BUILT AS NORMALLY -->
            <div class="swiper" id="swiper-hook">
                <div class="swiper-wrapper">
                    <div v-for="(item, index) in jsonEpisodes" :class="`swiper-slide swiper-slide-${index}`" :key="`swiper-slide-${index}`">
                        <div class="inner">
                            <div class="left">
                                <div class="stagger number">{{ item.title }}:</div>
                                <div class="stagger title">{{ item.subtitle }}</div>
                                <div class="stagger blurb" v-html="item.description"></div>
                            </div>
                            <div class="right">
                                <div class="stagger media large">
                                    <img @click="openAsset(item.imageBig, item.videoBig, index)" :src="require(`@/assets/images/episodes/${item.imageBig}`)"/>
                                    <img v-if="item.videoBig" class="icon" :src="require('@/assets/images/global/circle-play.svg')"/>
                                    <img v-else class="icon" :src="require('@/assets/images/global/search-plus.svg')"/>
                                </div>
                                <div class="stagger media small">
                                    <img @click="openAsset(item.imageSmall, item.videoSmall, index)" :src="require(`@/assets/images/episodes/${item.imageSmall}`)"/>
                                    <img v-if="item.videoSmall" class="icon" :src="require('@/assets/images/global/circle-play.svg')"/>
                                    <img v-else class="icon" :src="require('@/assets/images/global/search-plus.svg')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination">
            <div class="text">Chapter Guide</div>
            <div class="pagination-wrapper">
                <div class="swiper-button-prev">
                    <img  :src="require('@/assets/images/episodes/rectangle.svg')" />
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-next">
                    <img :src="require('@/assets/images/episodes/rectangle.svg')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap, { Power3 } from '@/assets/js/gsap/all'
import gh from '@/helpers/gh'
import 'swiper/swiper-bundle.min.css'
import Swiper, {Navigation, Pagination, Autoplay, Mousewheel} from 'swiper' // KARL - You can add plugins like this
Swiper.use([Navigation, Pagination, Autoplay, Mousewheel])
export default {
    name: "EpisodeSlideShow",
    data () {
        return {
            document: document,
            window: window,
            gh: gh,

            jsonEpisodes: []
        }
    },
    props: {
        loadMobile: {
            type: Boolean,
            required: true,
        },
    },
    mounted () {
        this.jsonEpisodes = this.window.config.json.global.site.episodes
        this.swiperInit()
    },
    methods: {
        reveal () {
            setTimeout(this.reveal2,10)
        },
        reveal2 () {
            //this.slider.slideTo(0,0)//slide to first slide, 0 seconds
            let tl = gsap.timeline({
                onComplete:this.reveal3
            })
            tl.staggerFromTo("#library-episodes .swiper-slide-0 .stagger", 0.5,
                {
                    opacity:0
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut,
                    stagger:0.15
                },
                0
            )
            tl.staggerFromTo("#library-episodes .swiper-slide-1 .stagger ", 0.5,
                {
                    opacity:0
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut,
                    stagger:0.15
                },
                0
            )
        },
        reveal3() {

        },

        swiperInit () {
            let initObject = {
                init: false,
                speed: 500,
                grabCursor: true,
                preventClicks: false,
                preventClicksPropagation: false,
                initialSlide: 0,
                mousewheel: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return `<div class="bullet-segment ${className}">
                                    <span class="bullet-line"></span>
                                    <span class="bullet-dot"></span>
                                    <span class="bullet-line"></span>
                                </div>`
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            }
            //doing manual breakbpoints for mobile.desktop.  vertical is too much for swiper and it needs re-created

            if (this.loadMobile) {
                initObject.slidesPerView = 'auto'
                initObject.spaceBetween = 50
                //initObject.centeredSlides = true
                initObject.loop = false
                initObject.autoHeight = true

                initObject.direction = "vertical"
            } else {
                initObject.slidesPerView = 1.75
                initObject.spaceBetween = 100
                initObject.centeredSlides = false
                initObject.loop = true
            }

            this.slider = new Swiper('#swiper-hook', initObject)

            this.slider.on('slideChangeTransitionStart', function (e) {
                let index = e.realIndex+1
                gh.pingAnalyticsCustom('chapters', index)
            }.bind(this))
            /*
            this.slider.on('slideChangeTransitionInit', function (e) {

            }.bind(this))

             */


            setTimeout(this.sliderInit2, 100)
        },
        sliderInit2 () {
            this.slider.init()
        },

        openAsset(image, video) {
            //only allow clicks if not animating
            if (!this.slider.animating) {
                let imageSrc = require(`@/assets/images/episodes/${image}`)
                if (video) {
                    let videoSrc = require(`@/assets/images/episodes/${video}`)
                    gh.openOverlay(imageSrc, videoSrc)
                } else {
                    gh.openOverlay(imageSrc)
                }
            }
        }
    }
}
</script>
