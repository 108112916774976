<template>
    <div v-if="show" id="enter" :class="['subsection', (!currentSubsection) ? 'visible' : '']">
        <div id="mobile-landing" class="show-mobile" >
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/images/mobile/landing.jpg') + ')'}"></div>
            <div class="bg">
                <div class="bg2">
                    <div class="scaler" data-scaler-w="619" data-scaler-h="1080">
                        <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/opening-loop.jpg') + ')'}"></div>
                        <div class="bgimg ">
                            <video id="mobile-loop" :Zposter="require('@/assets/video/mobile-loop.jpg')" muted autoplay loop playsinline webkit-playsinline>
                                <source :src="require('@/assets/video/mobile-loop.mp4')">
                            </video>
                        </div>
                    </div>
                    <div class="black-bottom"></div>
                </div>
            </div>
            <div class="dream-action-links">
                <div class="txt">
                    <span class="m1">Dream Dangerously</span>
                </div>
                <div class="signup-block txt">
                    <div class="cta">
                        <div class="ctabg">
                            <inline-svg :src="require('@/assets/images/global/signup-frame.svg')" />
                        </div>
                        <div @click="enterClick" class="ctaover"><span>ENTER</span></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="show-desktop scaler" data-scaler-w="1920" data-scaler-h="1080">
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/opening-loop.jpg') + ')'}"></div>
            <div class="bgimg ">
                <video id="desktop-loop" :Zposter="require('@/assets/video/opening-loop.jpg')" muted autoplay loop playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/opening-loop.mp4')">
                </video>
            </div>
            <div class="dream-action-links">
                <div class="txt">
                    <span class="m1">Dream Dangerously</span>
                </div>
                <div class="signup-block txt">
                    <div class="cta">
                        <div class="ctabg">
                            <inline-svg :src="require('@/assets/images/global/signup-frame.svg')" />
                        </div>
                        <div @click="enterClick" class="ctaover"><span>ENTER</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
export default {
    name: "Enter",
    data () {
        return {
            window: window,
            document:document,
            json: null,
            inPanelTransition: false,
            gh: gh,

            show:true,

            dissolveTime: 1250, //don't change this, it needs to be linked to the css step speed
        }
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition,
            triggerTrailerOpen: state => state.triggerTrailerOpen,
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
        }),
    },
    watch:{
        currentSubsectionNew() {
            setTimeout(this.enterGone,1000)
        }
    },
    methods: {
        enterClick() {
            this.$store.commit('setEnterIsClicked') //debug
            this.gh.setCompetingAudioPlaying(true)
            this.gh.unmuteGlobalVolume()
            this.gh.newSectionStart( 'interstitial')
        },
        enterGone() {
            this.show = false
        }
    }
}
</script>
