<template>
    <div v-if="(currentLibrarySubsection==='library-landing')" id="library-landing" :class="['library-subsection', (currentLibrarySubsection==='library-landing') ? 'visible' : '']" >
        <!--<div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/images/library/bg-library.jpg') + ')'}"></div>-->
        <div class="scaler" data-scaler-w="1920" data-scaler-h="1080">
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/bg-library.jpg') + ')'}"></div>
            <div class="bgimg">
                <video :Zposter="require('@/assets/video/bg-library.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/bg-library.mp4')">
                </video>
            </div>
        </div>
        <div class="desktop-hook scaler-contained show-desktop" data-scaler-w="2957" data-scaler-h="1492" data-extra-padding-x="0" data-extra-padding-y="100">
            <div class="items">
                <div class="stagger floating item item-the-library">
                    <div class="hook">
                        <div class="txt-icon" >
                            <div class="txt">The Library</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-library-2.svg')" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="stagger floating divider divider-1">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/library-divider-line.svg')" />
                    </div>
                </div>

                <div class="stagger floating item item-episode-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="gh.newSectionStart('library-episodes')" class="txt-icon" >
                            <div class="txt">Chapter Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-episode-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger floating divider divider-2">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/library-divider-line.svg')" />
                    </div>
                </div>

                <div class="stagger floating item item-waking-world-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('waking')" class="txt-icon" >
                            <div class="txt">Waking World Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-waking-world.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger floating divider divider-3">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/library-divider-line.svg')" />
                    </div>
                </div>

                <div class="stagger floating item item-dreaming-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('dreaming')" class="txt-icon" >
                            <div class="txt">The Dreaming Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-dreaming-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger floating divider divider-4">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/library-divider-line.svg')" />
                    </div>
                </div>

                <div class="stagger floating item item-endless-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('endless')" class="txt-icon" >
                            <div class="txt">The Endless Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-endless-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>


                <div class="stagger item item-character-map">
                    <div class="bg bg-full-width"></div>
                    <div @click="gh.newSectionStart('library-character-map')" class="txt-icon" >
                        <div class="txt">Character Relationship Catalog</div>
                        <div class="icon">
                            <img :src="require('@/assets/images/library/icon-character-map.svg')" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mobile-hook top-bottom-scrims show-mobile">

            <div class="items">
                <div class="stagger-horizontal floating-horizontal item item-the-library">
                    <div class="hook">
                        <div class="txt-icon" >
                            <div class="txt">The Library</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-library-2.svg')" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="stagger-horizontal floating-horizontal divider divider-1">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/divider-horizontal.svg')" />
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal item item-episode-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="gh.newSectionStart('library-episodes')" class="txt-icon" >
                            <div class="txt">Chapter Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-episode-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal divider divider-2">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/divider-horizontal.svg')" />
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal item item-waking-world-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('waking')" class="txt-icon" >
                            <div class="txt">Waking World Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-waking-world.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal divider divider-3">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/divider-horizontal.svg')" />
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal item item-dreaming-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('dreaming')" class="txt-icon" >
                            <div class="txt">The Dreaming Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-dreaming-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal divider divider-4">
                    <div class="hook">
                        <img :src="require('@/assets/images/library/divider-horizontal.svg')" />
                    </div>
                </div>

                <div class="stagger-horizontal floating-horizontal item item-endless-wing">
                    <div class="hook">
                        <div class="bg"></div>
                        <div @click="loadLibraryWing('endless')" class="txt-icon" >
                            <div class="txt">The Endless Wing</div>
                            <div class="icon">
                                <img :src="require('@/assets/images/library/icon-the-endless-wing.svg')" />
                            </div>
                        </div>
                    </div>
                </div>


                <div class="stagger-horizontal item item-character-map">
                    <div class="bg bg-full-width"></div>
                    <div @click="gh.newSectionStart('library-character-map')" class="txt-icon" >
                        <div class="txt">Character Relationship Catalog</div>
                        <div class="icon">
                            <img :src="require('@/assets/images/library/icon-character-map.svg')" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <BackButton target="wakingdreaming" copy="wakingdreaming"></BackButton>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import sinAnimate from '@/helpers/sinAnimate'
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import BackButton from "@/components/blocks/BackButton"
export default {
    name: "LibraryLanding",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,
            sinAnimate:sinAnimate
        }
    },
    components: {
        BackButton
    },
    computed: {
        ...mapState({
            currentLibrarySubsection: state => state.currentLibrarySubsection,
            isMobileWidth: state => state.isMobileWidth,
        }),
    },
    watch: {
        currentLibrarySubsection(val) {
            if (val === 'library-landing') {
                if (this.window.config.fastLoadMode) {
                    this.reveal()
                } else {
                    this.reveal()
                }
            }
        },
        isMobileWidth() {
            //re-reveal depending on mobile vs desktop
            if (this.currentLibrarySubsection === 'library-landing') {
                this.reveal()
            }
        }
    },
    methods: {
        reveal() {
            setTimeout(this.reveal2,10)
        },
        reveal2() {
            if (this.isMobileWidth) {
                sinAnimate.init('#library-landing .mobile-hook .floating-horizontal', 'library-landing-mobile')
            } else {
                sinAnimate.init('#library-landing .desktop-hook .floating', 'library-landing')
            }


            gsap.fromTo("#library-landing .item-character-map", 0.75,
                {
                    opacity:0
                },
                {
                    delay:1.5,
                    opacity:1,
                    ease: Power3.easeInOut,
                }
            )
        },

        loadLibraryWing(val) {
            this.$store.commit('setCurrentLibraryNamesContent', val)
            gh.newSectionStart('library-character-names')
        }
    }
}
</script>
