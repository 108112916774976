<template>
    <div  id="menu" :class="(showMenu) ? 'show' : 'hide'">
        <!--<div class="guide"><img :src="require('@/assets/images/menu/_guide_menu_desktop.png')"></div>-->
        <div @mouseenter="closeMenu" v-show="menuOpen"  id="menu-hover-offer" ></div>
        <div id="menu-controls" >
            <div @mouseenter="hamburgerEnter" @mouseleave="hamburgerLeave" @click="toggleMenu" :class="['hamburger',(menuOpen) ? 'open' : 'closed']">
                <div class="diamond-top"><div class="bg"></div></div>
                <div class="diamond-center">
                    <div class="bg"></div>
                    <div class="ls">
                        <span class="l l1"></span>
                        <span class="l l2"></span>
                        <span class="l l3"></span>
                    </div>
                </div>
                <div class="diamond-bottom"><div class="bg"></div></div>
            </div>
        </div>
        <div id="menu-sidenav" :class="[(menuOpen) ? 'open' : 'closed']">
            <div class="bgimg-desktop bgimg tc" :style="{'background-image': 'url(' + require('@/assets/images/menu/bg.jpg') + ')'}"></div>
            <div class="bgimg-mobile bgimg tc" :style="{'background-image': 'url(' + require('@/assets/images/menu/bg-mobile.jpg') + ')'}"></div>
            <div class="copy">
                <div @click="gh.newSectionStart('library-landing')" class="item hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div class="txt0">THE LIBRARY</div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>
                <div @click="gh.newSectionStart('losttools')" class="item hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div class="txt0">LOST TOOLS</div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>
                <div class="center-line"></div>

                <div class="dreamcast hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div @click="gh.newSectionStart('library-episodes')" class="txt2">Chapters</div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>
                <div class="dreamcast hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div class="txt2">
                        <a href="https://www.netflix.com/title/81150303" target="_blank">Watch Now on Netflix</a>
                    </div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>


                <div class="center-line"></div>
                <div class="line-break">
                    <div class="icon-spotify"><inline-svg :src="require('@/assets/images/global/icon-spotify.svg')" /></div>
                </div>
                <div @click="gh.openSpotify" class="dreamcast hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div class="txt1">
                        <span >
                            <div class="txt1">Listen To:</div>
                            <div class="txt2">THE SANDMAN: DREAMCAST</div>
                        </span>
                    </div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>

                <div class="dreamcast hover-hook">
                    <div class="arrow l"><inline-svg :src="require('@/assets/images/global/arrow-left.svg')" /></div>
                    <div class="txt2">
                        <a href="https://lnk.to/TheSandmanS1" target="_blank">The Soundtrack</a>
                    </div>
                    <div class="arrow r"><inline-svg :src="require('@/assets/images/global/arrow-right.svg')" /></div>
                </div>

                <Footer :isMenu="true"></Footer>
            </div>
        </div>
    </div>
    <div :class="audioMenuClass" v-show="!menuOpen" id="menu-sound">
        <div class="connecting-line"></div>
        <div class="diamond-center">
            <div class="bg"></div>
            <div id="menu-sound-bars" @click="gh.toggleGlobalVolume" :class="(globalVolume) ? 'unuted' : 'muted'">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
//import gsap, { Power3 } from '@/assets/js/gsap/all'
import Footer from "@/components/framework/Footer"
export default {
    name: "Menu",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            menuOpen:false,
            bgAudio:null,

            menuHamburgerOver:false,
        }
    },
    components: {
        Footer,
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            toggleMenuClose: state => state.toggleMenuClose,
            overlayObject: state => state.overlayObject,
            doingVideoTransition: state => state.doingVideoTransition,
            globalVolume: state => state.globalVolume,
            competingAudioPlaying: state => state.competingAudioPlaying,
        }),
        showMenu() {
            //if not in transition and not in overlay
            if (this.currentSubsection) {
                return (!this.doingVideoTransition && !this.overlayObject)
            } else {
                return false
            }
        },
        audioMenuClass() {
            //show on interstitial, otherwise use regular audio
            let ret = 'show'
            if (this.currentSubsectionNew === 'interstitial') {
                ret = 'interstitial'
            } else {
                ret = (this.showMenu) ? 'show' : 'hide'
            }
            if (ret === 'show' && this.menuHamburgerOver) {
                ret = 'hamburgerHover'
            }
            return ret
        }
    },
    mounted() {
        this.bgAudio = new Audio(require('@/assets/audio/bg.mp3'))
        this.bgAudio.loop = true
        if (this.currentSubsection || this.currentSubsectionNew) {
            this.reveal()
        }
    },
    watch: {
        currentSubsectionNew() {
            if (this.currentSubsectionNew) {
                this.reveal()
                this.closeMenu()
            }
        },
        competingAudioPlaying(val) {
            if (val) {
                this.pauseBGAudio()
            } else {
                this.playBGAudio()
            }

        },
        globalVolume(val) {
            if (val===1) {
                this.playBGAudio()
            } else {
                this.pauseBGAudio()
            }
        }
    },
    methods: {
        playBGAudio() {
            if (this.globalVolume && !this.competingAudioPlaying) {
                this.bgAudio.play()
            }
        },
        pauseBGAudio() {
            this.bgAudio.pause()
        },
        reveal() {
            /*
            // initial show menu after enter screen or fast start debug
            let sel = '#menu'
            let tl = gsap.timeline({})
            tl.fromTo(sel, 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0
            )
            gsap.set(sel, {visibility:'visible'})

             */
        },

        toggleMenu() {
            if (this.menuOpen) {
                this.closeMenu()
            } else {
                this.openMenu()
            }
        },
        openMenu() {
            this.menuOpen=true

            //TODO - build GSAP reveal on open

        },
        closeMenu() {
            this.menuOpen=false
        },

        hamburgerEnter() {
            this.menuHamburgerOver = true
        },
        hamburgerLeave() {
            this.menuHamburgerOver = false
        }
    }
}
</script>
