//import createjs from 'preload-js'
import store from '@/store'
import gsap, { Power3 } from '@/assets/js/gsap/all'
//import { useGtag } from "vue-gtag-next"
//import App from '@/App.vue'
import router from '@/router'
const gh = {
    globalSectionInit: function() {
        /*
        setTimeout(function() {
            //gh.aosInit()
        },500)

         */
    },
    scrollTo(route, window) {
        const tid = '#section-' + route
        gsap.to(window, {
            duration: 0.5,
            scrollTo:tid,
            easing: Power3.easeInOut
        })
    },
    enableBodyScroll(enable) {
        if (enable) {
            document.body.classList.remove("no-scroll")
            window.scrollTo(0, gh.bodyScrollY)
            document.body.style.top = 0
        } else {
            gh.bodyScrollY = window.scrollY
            document.body.classList.add("no-scroll")
            document.body.style.top = -gh.bodyScrollY+'px'
        }
    },
    stripSlashesFrontAndBack(val) {
        if (val) {
            val = val.toString()
            if(val.substr(-1) === '/') {
                val = val.substr(0, val.length - 1);
            }
            if(val.substr(0,1) === '/') {
                val = val.substr(1, val.length);
            }
        }
        return val
    },

    getRouteObjectFromVal(val, grabFromPath = false) {
        let ret = null
        let comparator = (grabFromPath) ? 'path' : 'hook'
        for (let i = 0;i < window.config.json.global.routes.length;i++) {
            let obj = window.config.json.global.routes[i]
            if (obj[comparator] === val) {
                ret = obj
            }
        }
        return ret
    },
    interstitialEnded() {
        let path = router.currentRoute.value.path
        let obj = gh.getRouteObjectFromVal(path, true)
        let finalVal = (obj && obj.hook) ? obj.hook : 'wakingdreaming'

        if (obj.hook === 'library-character-names') {
            store.commit('setCurrentLibraryNamesContent', obj.extra)
        }

        gh.newSectionStart(finalVal)
    },
    pingAnalyticsCustom(section, val, eventLabel=null,eventValue=null ) {
        let obj = {
            'event_category' : val,
            'event_label': eventLabel,
            'value': eventValue
        }
        //console.log('gtag', obj)
        window.gtag('event', section, obj)
    },
    pingAnalyticsChangeRoute(val) {
        if (val) {
            try {
                let eventLabel = null
                let eventValue = null
                if (store.state.currentSubsection?.includes('library')) {
                    eventLabel = String(store.state.currentLibrarySubsection)
                    eventValue = String(store.state.currentLibraryNamesContent)
                }
                gh.pingAnalyticsCustom('section', val, eventLabel, eventValue)
                let obj = gh.getRouteObjectFromVal(val)
                if (obj) {
                    router.push(obj)
                }

            } catch (e) {
                console.log('gtag', e, val)
            }
        }
    },

    newSectionStart(val) {
        store.commit('setPreviousSubsection',store.state.currentSubsection)
        store.commit('setCurrentSubsectionNew', val)

        gh.pingAnalyticsChangeRoute(val)
    },
    newSectionFinish() {
        //go to top of page
        //set previous (used in back buttons)
        //flip the new to the current
        //clear out new
        //also clear out inner page info
        window.scrollTo(0, 0)
        document.body.style.top = 0
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        store.commit('setPreviousSubsection',store.state.currentSubsection)
        store.commit('setCurrentSubsection',store.state.currentSubsectionNew)
        store.commit('setCurrentSubsectionNew',null)

        if (!store.state.currentSubsection?.includes('library')) {
            store.commit('setCurrentLibrarySubsection',null)
        }
        if (!store.state.currentSubsection?.includes('character-detail')) {
            store.commit('setCurrentCharacterSubsection',null)
        }
    },
    showSection(val) {
        let currentSubsection = String(store.state.currentSubsection)
        let currentSubsectionNew = String(store.state.currentSubsectionNew)
        return ( currentSubsection.includes(val) || currentSubsectionNew.includes(val) )
    },
    subsectionClasses(val) {
        let currentSubsection = String(store.state.currentSubsection)
        let currentSubsectionNew = String(store.state.currentSubsectionNew)
        let ret = ''
        if (currentSubsection.includes(val) || currentSubsectionNew.includes(val) ) {
            ret += ' visible '
            if (currentSubsection.includes(val)) {
                ret += ' subsection-current '
            } else if (currentSubsectionNew.includes(val)) {
                ret += ' subsection-new '
            }
        }
       return ret
    },
    openOverlay(imagePath, videoPath=null, imagePathMobile=null, videoPathMobile=null, extra='') {
        if (videoPath || extra === 'spotify') {
            gh.setCompetingAudioPlaying(true)
        }
        store.commit('setOverlay',{
            imagePath:imagePath,
            videoPath:videoPath,
            imagePathMobile:imagePathMobile,
            videoPathMobile:videoPathMobile,
            extra: extra
        })
    },
    closeOverlay() {
        gh.setCompetingAudioPlaying(false)
        store.commit('setOverlay',null)
    },
    openSpotify() {
        let imageSrc = require(`@/assets/images/spotify/bg.jpg`)
        let imageSrcMobile = require(`@/assets/images/spotify/bg-mobile.jpg`)
        gh.openOverlay(imageSrc, null, imageSrcMobile, null, 'spotify')
    },

    swipeHandler (e, eventMouse, item, sel) {
        //stop clicks occuring in swipe
        eventMouse.preventDefault()

        let overageX = parseFloat(item.getAttribute('data-overage-x'))
        let currentMouseSideOfScreen = item.getAttribute('data-currentMouseSideOfScreen')
        let newX = null

        let newCurrentMouseSideOfScreen = 'center'
        if (e === 'left') {
            //want to see more of right
            if (currentMouseSideOfScreen === 'center') {
                newX = -overageX
                newCurrentMouseSideOfScreen = 'right'
            } else if (currentMouseSideOfScreen === 'left') {
                newX = 0
            }
        } else if (e === 'right') {
            //want to see more of left
            if (currentMouseSideOfScreen === 'center') {
                newX = overageX
                newCurrentMouseSideOfScreen = 'left'
            } else if (currentMouseSideOfScreen === 'right') {
                newX = 0
            }
        }

        if (newX !== null) {
            gsap.to(sel, {
                x: newX,
                duration: 1,
                ease: Power3.easeInOut,
            })
            item.setAttribute('data-overage-newX', newX)
            item.setAttribute('data-currentMouseSideOfScreen', newCurrentMouseSideOfScreen)
        }

    },
    toggleGlobalVolume() {
        store.commit('toggleGlobalVolume')
    },
    muteGlobalVolume() {
        store.commit('muteGlobalVolume')
    },
    unmuteGlobalVolume() {
        store.commit('unmuteGlobalVolume')
    },
    setCompetingAudioPlaying(val) {
        store.commit('setCompetingAudioPlaying',val)
    },
    setTriggerResize() {
        store.commit('setTriggerResize')
    }
}
export default gh
