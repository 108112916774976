//REACTIVE
import { createStore } from 'vuex'
export const store = createStore({
    state () {
        return {
            smallHeader: false,
            overlayObject:null,
            triggerTransitionOverlay: Math.random(),
            routerNext: null,
            mobileCloseToggler:'',
            didInitialSiteTransition:false,
            doReveal:Math.random(),
            youTubeIsClickable: false,

            characterPageOpen:false,
            triggerTrailerOpen:Math.random(),

            allPreloaded: false,
            preloaderPreloaded: false,

            currentSubsectionNew: null,
            previousSubsection: null,
            currentSubsection: null,
            currentLibrarySubsection: null,
            currentLibraryNamesContent: 'dreaming',
            currentCharacterSubsection: null,
            wakingDreaming: 'dreaming',
            doingVideoTransition: false,

            isMobileWidth:false,

            mouseXPanUpdateToggler: Math.random(),

            globalVolume:0,
            competingAudioPlaying: false,

            triggerResize: Math.random(),
            enterIsClicked: false,
        }
    },
    mutations: {
        setEnterIsClicked(state) {
            state.enterIsClicked = true
        },
        setTriggerResize(state) {
            state.triggerResize = Math.random()
        },
        setCompetingAudioPlaying(state, val) {
            state.competingAudioPlaying = (val)
        },
        toggleGlobalVolume(state) {
            state.globalVolume = (state.globalVolume === 1) ? 0 : 1
        },
        muteGlobalVolume(state) {
            state.globalVolume = 0
        },
        unmuteGlobalVolume(state) {
            state.globalVolume = 1
        },

        setIsMobileWidth(state, val) {
            state.isMobileWidth = val
        },
        setExtraMouseX (state, val) {
            state.extraMouseX = val
        },
        setCurrentLibraryNamesContent (state, val) {
            state.currentLibraryNamesContent = val
        },
        setDoingVideoTransition (state, val) {
            state.doingVideoTransition = val
        },
        setWakingDreaming (state, val) {
            state.wakingDreaming = val
        },
        setCurrentSubsection (state, val) {
            state.currentSubsection = val
        },
        setCurrentSubsectionNew (state, val) {
            state.currentSubsectionNew = val
        },
        setPreviousSubsection (state, val) {
            state.previousSubsection = val
        },
        setCurrentCharacterSubsection (state, val) {
            state.currentCharacterSubsection = val
        },
        setCurrentLibrarySubsection (state, val) {
            state.currentLibrarySubsection = val
        },

        setAllPreloaded (state) {
            state.allPreloaded = true
        },
        setPreloaderPreloaded (state) {
            state.preloaderPreloaded = true
        },
        setTriggerTrailerOpen (state) {
            state.triggerTrailerOpen = Math.random()
        },
        setCharacterPageOpen (state, val) {
            state.characterPageOpen = val
        },
        setSmallHeader (state, val) {
            state.smallHeader = val
        },
        setOverlay(state, obj=null) {
            state.overlayObject = obj
        },
        triggerTransitionOverlay (state) {
            state.triggerTransitionOverlay = Math.random()
        },
        routerNext(state, next) {
            state.routerNext = next
        },
        closeMobileNav(state) {
            state.mobileCloseToggler = Math.random()
        },
        setDidInitialSiteTransition(state) {
            state.didInitialSiteTransition = true
        },
        triggerReveal(state) {
            state.doReveal = Math.random()
        },
        youTubeClickable(state) {
            state.youTubeIsClickable = true
        }
    }
})
export default store
