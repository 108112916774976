import {
    createApp
} from 'vue'
import App from './App.vue'
import axios from 'axios'

//globals
import InlineSvg from 'vue-inline-svg'


//state
import {
    store
} from './store'

//routes
//import VueGtag from "vue-gtag-next"
import router from './router'

//templates
import Main from "@/components/pages/Main"
import Generic from "@/components/pages/Generic"

//vue touch events
import Vue3TouchEvents from "vue3-touch-events"

//GLOBAL CONFIG
/*
let craftApiPreviewToken = ''
if (window.siteConfig?.xCraftLivePreview) {
    craftApiPreviewToken = '?tokenParam=' + window.siteConfig?.xCraftLivePreview + '&token=' + window.siteConfig?.xCraftLivePreviewToken
}
 */
window.recache = 'jijjer'
window.config = {
    fastLoadMode: false,
    fastLoadModeEnterClicked: false,
    metaTitleAddon: 'The Sandman',
    loadedOnce: false,
    routerInTransition: false,
    apiroot: (window.localMode) ? '/api/' : '/api/',
    //apiext: (window.localMode) ? '.json' : craftApiPreviewToken,
    apiext: (window.localMode) ? '.json' : '.json',
    localMode: (window.localMode),
    json: {
        global: null
    },
}
if (window.localMode) {
    window.config.fastLoadMode = true // skip preloder and intro
    window.config.fastLoadModeEnterClicked = true //also skip enter button
    if (process.env.VUE_APP_IS_MATTHEW) {
        //Matthew: this are is just for me: library-character-detail-lucienne
        window.config.fastLoadSkipIntro = false
        window.config.fastLoadMode = false
        window.config.fastLoadModeEnterClicked = false
        window.config.fastLoadModeStarterScreen = '' //wakingdreaming, library-character-map
    } else {
        //KARL: PUT YOUR CUSTOM STUFF HERE
        // window.config.fastLoadMode = false // skip preloder and intro
        // window.config.fastLoadModeEnterClicked = false //also skip enter button
        window.config.fastLoadModeStarterScreen = 'library-episodes'
        // window.config.fastLoadModeStarterScreen = 'losttools'
        // window.config.fastLoadModeStarterScreen = 'library-character-map'
    }

}
axios.get((`${window.config.apiroot}global${window.config.apiext}`))
    .then((res) => {

        //GLOBAL CONFIG
        window.config.json.recomiple = true
        window.config.json.global = res.data

        //DYNAMIC ROUTES
        for (let i = 0; i < res.data.routes.length; i++) {
            let obj = res.data.routes[i]
            let newobj = {
                path: obj.path,
                meta: {
                    title: obj.title,
                    type: obj.type
                },
            }
            switch (obj.type) {
                case 'home':
                    newobj.component = Main
                    break
                case 'generic':
                    newobj.component = Generic
                    break
            }
            router.addRoute(newobj)
        }


        //page not found
        let newobj = {
            name: 'page-not-found',
            path: "/:catchAll(.*)",
            component: Generic,
            meta: {
                title: '404'
            }
        }
        router.addRoute(newobj)

        //START APP
        startApp()
    }).catch(() => {})

function startApp() {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)


    //vtouch events
    app.use(Vue3TouchEvents)


    //ANALYTICS - in your main.js, below loading your router
    /*
    if (!window.localMode) {
        app.use(VueGtag, {
            property: {
                id: window.config.json.global.config?.gaTag
            }
        })
    }

     */

    //global inline svg
    app.component('inline-svg', InlineSvg)

    app.mount('#app')
}
