<template>
    <div v-show="gh.showSection('wakingdreaming')" id="waking-dreaming" :class="['subsection', gh.subsectionClasses('wakingdreaming')]">
        <WakingDreamingContent v-if="wakingDreamingToggler && enterIsClicked" :scalerW="scalerW" :scalerH="scalerH" :key="`wd${wakingDreamingToggler}`"></WakingDreamingContent>
    </div>
</template>
<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
import gsap, {Power3} from '@/assets/js/gsap/all'
import WakingDreamingContent from "@/components/subsections/wakingdreaming/WakingDreamingContent"
import {isMobile} from 'mobile-device-detect'
export default {
    name: "WakingDreaming",
    data () {
        return {
            window: window,
            document:document,
            json: null,
            inPanelTransition: false,
            gh: gh,

            dissolveTime: 1250, //don't change this, it needs to be linked to the css step speed

            wakingDreamingToggler:null,

            scalerW:2500,
            scalerH:1080,
        }
    },
    components: {
        WakingDreamingContent,
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            allPreloaded: state => state.allPreloaded,
            isMobileWidth: state => state.isMobileWidth,
            enterIsClicked: state => state.enterIsClicked,
        }),
    },
    mounted() {
        if (this.currentSubsectionNew === 'wakingdreaming') {
            this.$store.commit('setWakingDreaming', 'dreaming') //debug
            this.reveal()
        }
    },
    watch: {
        isMobileWidth() {
            if (this.allPreloaded) {
                this.rekeyContent()
            }
        },
        currentSubsectionNew () {
            if (this.currentSubsectionNew === 'wakingdreaming') {
                this.reveal(true)
            }
        },
        allPreloaded() {
            this.rekeyContent()
        }
    },
    methods: {
        rekeyContent() {
            if (this.isMobileWidth || isMobile) {
                this.scalerW = 1239
                this.scalerH = 1080
            } else {
                this.scalerW = 2500
                this.scalerH = 1080
            }
            this.wakingDreamingToggler = Math.random()
            setTimeout(this.rekeyContent2,10)
            setTimeout(this.rekeyContent2,500)
            setTimeout(this.rekeyContent2,1000)
        },
        rekeyContent2() {
            this.gh.setTriggerResize() //fix issue with screen being black when you cross between wakign and dreaming
        },
        reveal (forceReveal = false) {
            if (!this.revealedOnce || forceReveal) {
                //do this once, first time
                //if (!this.revealedOnce) {}

                let sel = '#waking-dreaming'
                let tl = gsap.timeline({
                    onComplete:this.reveal2
                })
                tl.fromTo(sel, 0.5,
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        ease: Power3.easeInOut
                    },
                    0
                )
                gsap.set(sel, { visibility: 'visible' })
                gh.newSectionFinish()

                if (!this.revealedOnce && this.window.config.fastLoadModeStarterScreen !=='wakingdreaming') {
                    gh.newSectionStart(this.window.config.fastLoadModeStarterScreen)
                }

                this.revealedOnce = true
            }

        },
    }
}
</script>
