<template>
    <div :class="['back-button', (doingVideoTransition) ? 'hide' : 'show']">
        <div @click="buttonClick" class="back-button-items">
            <div class="back-button-material-icons material-icons">west</div>
            <div class="back-button-bg"></div>
            <div class="back-button-text" >
                <div class="back-button-text2" v-html="generateCopy"></div>
            </div>
            <div class="back-button-decoration">
                <img :src="require('@/assets/images/global/decoration-back-button.svg')" />
            </div>
        </div>
    </div>
</template>
<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
export default {
    name: "BackButton",
    props: {
        // note - coy and target can be set specifically.
        // OR, if copy is wakingdreaming AND target is wakingdreaming, then it behaves as a variable and will display either waking or dreaming depening gon what section you were in
        copy: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            required: true,
        },
        extraDetail: {
            type: String,
            required: false,
            defautl: null
        },
    },
    computed: {
        ...mapState({
            wakingDreaming: state => state.wakingDreaming,
            doingVideoTransition: state => state.doingVideoTransition,
        }),
        generateCopy() {
            let ret = this.copy
            if (this.copy === 'wakingdreaming') {
                if (this.wakingDreaming === 'dreaming') {
                    ret = 'The Dreaming Realm'
                } else {
                    ret = 'The Waking Realm'
                }
            }
            return ret
        }
    },
    methods: {
        buttonClick() {
            gh.newSectionStart( this.target)
        },

    }
}
</script>
