<template>
    <div v-if="(String(currentLibrarySubsection).includes('library-character-detail'))" id="library-character-detail"
         :class="['library-subsection', (String(currentLibrarySubsection).includes('library-character-detail')) ? 'visible' : '']">
        <CharacterContent v-if="(String(currentLibrarySubsection).includes('library-character-detail')) && characterName" :key="toggler" :rekey="toggler" :characterName="characterName"></CharacterContent>
        <BackButton :target="backButtonTarget" :copy="backButtonCopy"></BackButton>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import BackButton from "@/components/blocks/BackButton"
import CharacterContent from "@/components/subsections/character/CharacterContent"
export default {
    name: "LibraryCharacterDetail",
    components: {
        CharacterContent,
        BackButton
    },
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            isRevealing: false,
            characterName:null,
            toggler:Math.random()
        }
    },
    computed: {
        ...mapState({
            currentLibrarySubsection: state => state.currentLibrarySubsection,
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            previousSubsection: state => state.previousSubsection,
            currentLibraryNamesContent: state => state.currentLibraryNamesContent,
        }),
        backButtonCopy() {
            let ret = 'The Library'
            if (this.previousSubsection === 'library-character-map') {
                ret = 'Character Relationship Catalog'
            } else if (this.previousSubsection === 'losttools') {
                ret = 'Lost Tools'
            } else if (this.previousSubsection === 'library-character-names') {
                if (this.currentLibraryNamesContent==='dreaming') {
                    ret = 'The Dreaming Wing'
                } else if (this.currentLibraryNamesContent==='waking') {
                    ret = 'The Waking World Wing'
                } else {
                    ret = 'The Endless Wing'
                }
            }
            return ret
        },
        backButtonTarget() {
            let ret = 'library-landing'
            if (this.previousSubsection === 'library-character-map') {
                ret = this.previousSubsection
            } else if (this.previousSubsection === 'losttools') {
                ret = 'losttools'
            } else if (this.previousSubsection === 'library-character-names') {
                ret = 'library-character-names'
            }
            return ret
        }
    },
    mounted() {

    },
    watch: {
        currentLibrarySubsection (val) {
            if (String(val).includes('library-character-detail')) {
                val = String(val).replace('library-character-detail-','')
                this.characterName = val
                this.toggler = Math.random()
                if (this.window.config.fastLoadMode) {
                    this.reveal()
                } else {
                    this.reveal()
                }
            }
        },
    },
    methods: {
        reveal() {
            this.isRevealing = true
            setTimeout(this.reveal2,10)
        },
        reveal2() {
            // set character sub section
            this.$store.commit('setCurrentCharacterSubsection', this.characterName)

            let tl = gsap.timeline({
                onComplete:this.revealComplete
            })
            tl.fromTo('#library-character-detail .character-content', 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0
            )
            tl.fromTo('#library-character-detail .back-button', 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0.25
            )
        },
        revealComplete() {
            this.isRevealing = false
        }
    } //methods
}
</script>
