import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
//import gh from '@/helpers/gh'

const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes: [], //defined globally in main
    scrollBehavior () {
        //scroll to top on all page changes
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (window.config.loadedOnce === false) {
        window.config.loadedOnce = true
        if (window.config.fastLoadModeEnterClicked) {
            store.commit('setEnterIsClicked')
            store.commit('setCurrentSubsectionNew', 'wakingdreaming')
        }
    }
    next()
})

/*
router.beforeEach((to, from, next) => {
    if (window.config.loadedOnce === false) {
        //first load, just bring in
        window.config.loadedOnce = true
        //store.commit('routerNext', next)
        next()
        setTimeout(function () {
            //store.commit('triggerTransitionOverlay', true)
        }, 500)
    } else {
        // all other loads, fade out old content then bring in
        next()
        //store.commit('routerNext', next)
        //store.commit('triggerTransitionOverlay', true)
    }
})
 */

router.afterEach((to) => {
    //set new title after gate
    let metaTitle = to.meta.title
    if (window.config.metaTitleAddon !== metaTitle) {
        metaTitle += (metaTitle) ? ' - ' + window.config.metaTitleAddon : window.config.metaTitleAddon
    }
    document.title = metaTitle
    //gh.aosRefresh()
})
export default router
