<template>
    <div v-show="gh.showSection('library')" id="library" :class="['subsection', (String(this.currentSubsection).includes('character-detail')) ? 'character-detail' : '', gh.subsectionClasses('library')]">
        <div class="content">
            <LibraryLanding></LibraryLanding>
            <LibraryEpisodes></LibraryEpisodes>
            <LibraryCharacterNames></LibraryCharacterNames>
            <LibraryCharacterMap></LibraryCharacterMap>
            <LibraryCharacterDetail></LibraryCharacterDetail>
        </div>

        <div v-show="doingVideoTransition" class="library-intro-vids show-desktop scaler" data-scaler-w="1920" data-scaler-h="1080">
            <div  class="bgimg">
                <video id="library-video" :Zposter="require('@/assets/video/intro-library.jpg')" muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/intro-library.mp4')">
                </video>
            </div>
        </div>
        <div v-show="doingVideoTransition" class="library-intro-vids show-mobile scaler" data-scaler-w="619" data-scaler-h="1080">
            <div class="bgimg">
                <video id="mobile-library-video" :Zposter="require('@/assets/video/mobile-intro-library.jpg')" muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/mobile-intro-library.mp4')">
                </video>
            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import LibraryLanding from "@/components/subsections/library/LibraryLanding"
import LibraryEpisodes from "@/components/subsections/library/LibraryEpisodes"
import LibraryCharacterNames from "@/components/subsections/library/LibraryCharacterNames"
import LibraryCharacterMap from "@/components/subsections/library/LibraryCharacterMap"
import LibraryCharacterDetail from "@/components/subsections/library/LibraryCharacterDetail"
export default {
    name: "Library",
    components: {
        LibraryLanding,
        LibraryEpisodes,
        LibraryCharacterNames,
        LibraryCharacterMap,
        LibraryCharacterDetail,
    },
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            vid: null,
        }
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            doingVideoTransition: state => state.doingVideoTransition,
            previousSubsection: state => state.previousSubsection,
            isMobileWidth: state => state.isMobileWidth,
        }),
    },
    mounted() {

    },
    watch: {
        currentSubsectionNew(val) {
            if (String(val).includes('library')) {
                if (this.window.config.fastLoadMode) {
                    this.reveal3() //reveal3 to skip video, reveal to see video
                } else {
                    //if already in library, or coming from lost tools, don't show library intro again
                    let skipIntroVideo = false
                    if (String(this.currentSubsection).includes('library')) {
                        skipIntroVideo = true
                    } else if (this.previousSubsection === 'losttools') {
                        skipIntroVideo = true
                    }

                    if (skipIntroVideo) {
                        this.reveal3()
                    } else {
                        this.reveal()
                    }
                }
            }
        }
    },
    methods: {
        reveal() {
            gsap.set('.library-intro-vids', {  opacity:0 })
            gsap.set('#library .content', {  opacity:1 })
            this.$store.commit('setDoingVideoTransition', true)
            this.gh.setTriggerResize()
            setTimeout(this.reveal2,10)
        },
        reveal2() {
            //fade in
            let tl = gsap.timeline({})
            tl.fromTo('.library-intro-vids', 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0
            )

            // play video then reveal in content when video is done
            if (this.isMobileWidth) {
                this.vid = document.getElementById("mobile-library-video")
            } else {
                this.vid = document.getElementById("library-video")
            }
            if (this.vid) {
                this.vid.pause()
                this.vid.addEventListener("ended", this.reveal3)
                this.vid.volume = 0
                this.vid.muted = true
                this.vid.currentTime=0
                this.vid.play()
            }
        },
        reveal3() {
            // set library sub section
            gsap.set('#library .content', {  opacity:1 })
            this.$store.commit('setCurrentLibrarySubsection', String(this.currentSubsectionNew))
            gsap.to('.library-intro-vids', 0.5,
                {
                    opacity:0,
                    ease: Power3.easeInOut,
                    onComplete:this.revealComplete
                },
                0
            )
        },
        revealComplete() {
            if (this.vid) {
                this.vid.removeEventListener("ended", this.reveal3)
                this.vid.currentTime = 0
                this.vid.pause()
            }
            gh.newSectionFinish()
            this.$store.commit('setDoingVideoTransition', false)
            this.gh.setTriggerResize()
        }
    } //methods
}
</script>
