<template>
    <div v-if="(currentLibrarySubsection==='library-character-names')" id="library-character-names" :class="['library-subsection', (currentLibrarySubsection==='library-character-names') ? 'visible' : '']" >
        <!--<div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/images/library/bg-library.jpg') + ')'}"></div>-->
        <div class="scaler" data-scaler-w="1920" data-scaler-h="1080">
            <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/bg-library.jpg') + ')'}"></div>
            <div class="bgimg">
                <video :Zposter="require('@/assets/video/bg-library.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/bg-library.mp4')">
                </video>
            </div>
        </div>
        <div class="desktop-hook show-desktop scaler-contained" :data-scaler-w="scalerW" :data-scaler-h="scalerH" :data-extra-padding-x="scalerExtraPaddingX" :data-extra-padding-y="scalerExtraPaddingY">
            <div :class="`items ${currentLibraryNamesContent}`">
                <div :class="`stagger floating item item-title ${currentLibraryNamesContent}`">
                    <div class="hook">
                        <div class="vertical" >
                            <div class="txt" v-if="currentLibraryNamesContent === 'dreaming'">The Dreaming Wing</div>
                            <div class="txt" v-if="currentLibraryNamesContent === 'waking'">The Waking World Wing</div>
                            <div class="txt" v-if="currentLibraryNamesContent === 'endless'">The Endless Wing</div>
                        </div>
                    </div>
                </div>
                <template class="floating-names abs100" v-for="(item, index) in jsonNames" :key="`jsonNames-${index}`">
                    <div v-if="!item.hideInCharacterNames" :class="`stagger floating divider divider-${index+1}`">
                        <div class="hook"></div>
                    </div>
                    <div v-if="!item.hideInCharacterNames" :class="[`stagger floating item i${index+1} item-${item.link}`, (item.twoline) ? 'item-twoline' : '']">
                        <div class="hook">
                            <div class="item2">
                                <div class="bg"></div>
                                <div @click="gh.newSectionStart('library-character-detail-'+item.link)" class="txt-icon" >
                                    <div class="txt" v-html="item.name"></div>
                                    <div class="icon">
                                        <img v-if="item.link==='raven'" :src="require('@/assets/images/global/ornamentation-top-and-bottom-two-lines.svg')" />
                                        <img v-else :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>


            </div>
        </div>

        <div class="mobile-hook top-bottom-scrims show-mobile">
            <div :class="`items ${currentLibraryNamesContent}`">
                <div :class="`stagger floating-horizontal item item-title ${currentLibraryNamesContent}`">
                    <div class="hook">
                        <div class="vertical" >
                            <div class="txt" v-if="currentLibraryNamesContent === 'dreaming'">The Dreaming Wing</div>
                            <div class="txt" v-if="currentLibraryNamesContent === 'waking'">The Waking World Wing</div>
                            <div class="txt" v-if="currentLibraryNamesContent === 'endless'">The Endless Wing</div>
                        </div>
                    </div>
                </div>
                <template class="floating-names abs100" v-for="(item, index) in jsonNames" :key="`jsonNames-${index}`">
                    <div v-if="!item.hideInCharacterNames" :class="`stagger floating-horizontal divider divider-${index+1}`">
                        <div class="hook"></div>
                    </div>
                    <div v-if="!item.hideInCharacterNames" :class="[`stagger floating-horizontal item i${index+1} item-${item.link}`, (item.twoline) ? 'item-twoline' : '']">
                        <div class="hook">
                            <div class="item2">
                                <div class="bg"></div>
                                <div @click="gh.newSectionStart('library-character-detail-'+item.link)" class="txt-icon" >
                                    <div class="txt" v-html="item.name"></div>
                                    <div class="icon">
                                        <img v-if="item.twoline" :src="require('@/assets/images/global/ornamentation-top-and-bottom-two-lines.svg')" />
                                        <img v-else :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </div>

        <BackButton target="library-landing" copy="The Library"></BackButton>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import sinAnimate from '@/helpers/sinAnimate'
import { mapState } from 'vuex'
//import gsap, { Power3 } from '@/assets/js/gsap/all'
import BackButton from "@/components/blocks/BackButton"
export default {
    name: "LibraryCharacterNames",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,
            sinAnimate: sinAnimate,

            jsonNames: []
        }
    },
    components: {
        BackButton
    },
    mounted() {
        this.setJsonNames()
    },
    computed: {
        ...mapState({
            currentLibrarySubsection: state => state.currentLibrarySubsection,
            currentLibraryNamesContent: state => state.currentLibraryNamesContent,
            isMobileWidth: state => state.isMobileWidth,
        }),
        scalerW() {
            return 2340
        },
        scalerH() {
            return 767
        },
        scalerExtraPaddingX() {
            if (this.currentLibraryNamesContent==='dreaming' || this.currentLibraryNamesContent==='waking') {
                return 0
            } else {
                return 0
            }
        },
        scalerExtraPaddingY() {
            return 0
        },
    },
    watch: {
        currentLibrarySubsection(val) {
            if (val === 'library-character-names') {
                if (this.window.config.fastLoadMode) {
                    this.reveal()
                } else {
                    this.reveal()
                }
            }
        },
        currentLibraryNamesContent() {
            this.setJsonNames()
        },
        isMobileWidth() {
            //re-reveal depending on mobile vs desktop
            if (this.currentLibrarySubsection === 'library-character-names') {
                this.reveal()
            }
        }
    },
    methods: {
        reveal() {
            setTimeout(this.reveal2,10)
        },
        reveal2() {
            if (this.isMobileWidth) {
                sinAnimate.init('#library-character-names .mobile-hook .stagger', 'library-character-names-mobile')
            } else {
                sinAnimate.init('#library-character-names .desktop-hook .stagger', 'library-character-names')
            }

        },
        setJsonNames() {
            this.jsonNames = this.window.config.json.global.site.libraryNames[this.currentLibraryNamesContent]
        }
    }
}
</script>
