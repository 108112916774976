//import createjs from 'preload-js'
import gsap, { Power3 } from '@/assets/js/gsap/all'
const scrollyRolly = {
    elemScrollItems: [],
    elemScrollItemsLeftAdjust: [],

    //just for characters page
    elemSmokeBg: null,
    masterThis: null,

    destroy(sel) {
        if (sel) {
            gsap.killTweensOf(sel)
        }
        window.removeEventListener('scroll', scrollyRolly.onScroll)
        window.removeEventListener('resize', scrollyRolly.onResize)
    },
    init(sel, elemSmokeBg=null, masterThis=null) {
        scrollyRolly.destroy(sel)

        window.addEventListener('scroll', scrollyRolly.onScroll)
        window.addEventListener('resize', scrollyRolly.onResize)

        scrollyRolly.elemScrollItems = document.querySelectorAll(sel)
        scrollyRolly.elemSmokeBg = elemSmokeBg
        scrollyRolly.masterThis = masterThis

        //initial plcement
        let directionUp = true
        let wavyMaker = 0
        let wavyMakerIncrement = 0.2
        for (let i=0;i<scrollyRolly.elemScrollItems.length;i++) {
            scrollyRolly.elemScrollItemsLeftAdjust[i] = wavyMaker
            if (directionUp) {
                wavyMaker+=wavyMakerIncrement
                if (wavyMaker>1) {
                    directionUp = false
                }
            }
            if (!directionUp) {
                wavyMaker-=wavyMakerIncrement
                if (wavyMaker<=0) {
                    directionUp = true
                }
            }
        }
        scrollyRolly.onScroll()
    },
    onResize() {
        scrollyRolly.onScroll()
    },
    onScroll() {
        //let leftAdjuster = this.window.innerWidth*0.25 //V1
        let leftAdjuster = window.innerWidth*0.15 //V2

        if (scrollyRolly.elemSmokeBg) {
            let bounding = scrollyRolly.elemSmokeBg.getBoundingClientRect()
            scrollyRolly.masterThis.lockSmokeBg = (window.scrollY > bounding.height) //manually fix to top
            if (scrollyRolly.masterThis.isMobileWidth) {
                if (!scrollyRolly.masterThis.part2TextIsUnlocked) {
                    //for mobile, start reveal sooner
                    if (window.scrollY + (window.innerHeight/3) > bounding.height) {
                        scrollyRolly.masterThis.revealPart2()
                    }
                }
            } else {
                if (scrollyRolly.masterThis.lockSmokeBg && !scrollyRolly.masterThis.part2TextIsUnlocked) {
                    scrollyRolly.masterThis.revealPart2()
                }
            }
        }
        for (let i=0;i<scrollyRolly.elemScrollItems.length;i++) {
            let item = scrollyRolly.elemScrollItems[i]
            let bounding = item.getBoundingClientRect()
            let pctOnScreen = (window.innerHeight-bounding.top - (bounding.height/2))/window.innerHeight
            if (pctOnScreen<0) {
                pctOnScreen = 0
            } else if (pctOnScreen>1) {
                pctOnScreen = 1
            }

            // center of screen, center of image height = opacity 1 || top and bottom = opacity 0
            let opacity = pctOnScreen*2
            if (opacity > 1) {
                opacity = 2 - opacity
            }
            // scaler - uses same multipler as opacity (1 in middle 0 in edges), but adjusted to not go to 0
            let scaler = 0.5 + opacity
            if (scaler>1) scaler = 1


            //VERSION 2 - COOLER
            let leftShift = scrollyRolly.elemScrollItemsLeftAdjust[i] * leftAdjuster
            if (scrollyRolly.elemScrollItemsLeftAdjust[i]<0.5) {
                leftShift += (opacity*leftAdjuster*0.8)
            } else if (scrollyRolly.elemScrollItemsLeftAdjust[i]>0.5) {
                leftShift -= (opacity*leftAdjuster*0.8)
            }
            leftShift -= (leftAdjuster/2)
            gsap.set(item,
                {
                    opacity:opacity,
                    duration:0.3,
                    x:leftShift,
                    scale:scaler,
                    ease:Power3.easeInOut
                }
            )
        }
    },//onscroll

}
export default scrollyRolly
