<template>
    <div v-if="(currentLibrarySubsection==='library-character-map')" id="library-character-map" :class="['library-subsection', (currentLibrarySubsection==='library-character-map') ? 'visible' : '']" >
        <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/images/library/bg-character-map.jpg') + ')'}"></div>

        <div class="scaler-contained show-desktop" data-scaler-w="3446" data-scaler-h="2152" data-extra-padding-x="-100" data-extra-padding-y="0">
            <div class="lines">
                <div class="octogon octogon-1">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-1.svg')" />
                </div>
                <div class="octogon octogon-2">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-2.svg')" />
                </div>
                <div class="octogon octogon-3">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-3.svg')" />
                </div>
                <div class="center">
                    <inline-svg :src="require('@/assets/images/library/map-lines-center.svg')" />
                </div>
                <div class="octtxt octogon-1-txt"><span>Chapters 7 - 10</span></div>
                <div class="octtxt octogon-2-txt"><span>Chapters 1 - 5</span></div>
                <div class="octtxt octogon-3-txt"><span>Chapters 2 - 10</span></div>

            </div>
            <div class="items">
                <!------>
                <div class="item item-the-library">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-landing')" class="txt-icon" >
                        <div class="txt">The Library</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/library/icon-the-library.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="item item-center">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-dream')" class="txt-icon" >
                        <div class="txt">The Sandman</div>
                        <div class="txt subtitle">Master Of Dreams</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/library/ornamentation-triangle-two-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger1 item triangle item-despair">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-despair')" class="txt-icon" >
                        <div class="txt">Despair</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item two-line item-matthew-the-raven">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-raven')" class="txt-icon" >
                        <div class="txt">Matthew The Raven</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item triangle item-death">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-death')" class="txt-icon" >
                        <div class="txt">Death</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item triangle item-desire">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-desire')" class="txt-icon" >
                        <div class="txt">Desire</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item two-line item-johanna-constantine">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-constantine')" class="txt-icon" >
                        <div class="txt">Johanna Constantine</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item item-lucifer">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lucifer')" class="txt-icon" >
                        <div class="txt">Lucifer</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>


                <!------>
                <div class="stagger2 item item-lyta-hall">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lyta')" class="txt-icon" >
                        <div class="txt">Lyta Hall</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-rose-walker">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-rose')" class="txt-icon" >
                        <div class="txt">Rose Walker</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-gilbert">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-gilbert')" class="txt-icon" >
                        <div class="txt">Gilbert</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-unity-kincaid">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-unity')" class="txt-icon" >
                        <div class="txt">Unity Kincaid</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger3 item item-john-dee">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-john')" class="txt-icon" >
                        <div class="txt">John Dee</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger3 item two-line item-roderick-burgess">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-roderick')" class="txt-icon" >
                        <div class="txt">Roderick Burgess</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger3 item item-ethel-cripps">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-ethel')" class="txt-icon" >
                        <div class="txt">Ethel Cripps</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger4 item item-lucienne">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lucienne')" class="txt-icon" >
                        <div class="txt">Lucienne</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger4 item item-cain-and-abel">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-cainabel')" class="txt-icon" >
                        <div class="txt">Cain &amp; Abel</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!--use incorrect height (i.e., not 2152) to fit this up more instead of dipping into the raven guide-->
        <div v-touch:swipe="swipeHandler"  id="mobile-scaler-yesyesyall" class="scaler-lr show-mobile"  data-scaler-lr="" data-nudge-x="100" :data-scaler-lr-w="3446" :data-scaler-lr-h="2152">
            <div class="lines">
                <div class="octogon octogon-1">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-1.svg')" />
                </div>
                <div class="octogon octogon-2">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-2.svg')" />
                </div>
                <div class="octogon octogon-3">
                    <inline-svg :src="require('@/assets/images/library/map-lines-octogon-3.svg')" />
                </div>
                <div class="center">
                    <inline-svg :src="require('@/assets/images/library/map-lines-center.svg')" />
                </div>
                <div class="octtxt octogon-1-txt"><span>Chapters 7 - 10</span></div>
                <div class="octtxt octogon-2-txt"><span>Chapters 1 - 5</span></div>
                <div class="octtxt octogon-3-txt"><span>Chapters 2 - 10</span></div>

            </div>
            <div class="items">
                <!------>
                <div class="item item-the-library">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-landing')" class="txt-icon" >
                        <div class="txt">The Library</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/library/icon-the-library.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="item item-center">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-dream')" class="txt-icon" >
                        <div class="txt">The Sandman</div>
                        <div class="txt subtitle">Master Of Dreams</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/library/ornamentation-triangle-two-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger1 item triangle item-despair">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-despair')" class="txt-icon" >
                        <div class="txt">Despair</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item two-line item-matthew-the-raven">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-raven')" class="txt-icon" >
                        <div class="txt">Matthew The Raven</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item triangle item-death">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-death')" class="txt-icon" >
                        <div class="txt">Death</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item triangle item-desire">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-desire')" class="txt-icon" >
                        <div class="txt">Desire</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-triangle-top.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item two-line item-johanna-constantine">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-constantine')" class="txt-icon" >
                        <div class="txt">Johanna Constantine</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger1 item item-lucifer">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lucifer')" class="txt-icon" >
                        <div class="txt">Lucifer</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>


                <!------>
                <div class="stagger2 item item-lyta-hall">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lyta')" class="txt-icon" >
                        <div class="txt">Lyta Hall</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-rose-walker">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-rose')" class="txt-icon" >
                        <div class="txt">Rose Walker</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-gilbert">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-gilbert')" class="txt-icon" >
                        <div class="txt">Gilbert</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger2 item item-unity-kincaid">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-unity')" class="txt-icon" >
                        <div class="txt">Unity Kincaid</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger3 item item-john-dee">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-john')" class="txt-icon" >
                        <div class="txt">John Dee</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger3 item two-line item-roderick-burgess">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-roderick')" class="txt-icon" >
                        <div class="txt">Roderick Burgess</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger3 item item-ethel-cripps">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-ethel')" class="txt-icon" >
                        <div class="txt">Ethel Cripps</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

                <!------>
                <div class="stagger4 item item-lucienne">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-lucienne')" class="txt-icon" >
                        <div class="txt">Lucienne</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>
                <div class="stagger4 item item-cain-and-abel">
                    <div class="bg"></div>
                    <div @click="gh.newSectionStart('library-character-detail-cainabel')" class="txt-icon" >
                        <div class="txt">Cain &amp; Abel</div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/ornamentation-top-and-bottom-one-line.svg')" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { DrawSVGPlugin } from '@/assets/js/gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin)
export default {
    name: "LibraryCharacterMap",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            mouseItem: null,
        }
    },
    components: {

    },
    computed: {
        ...mapState({
            currentLibrarySubsection: state => state.currentLibrarySubsection
        }),
    },
    watch: {
        currentLibrarySubsection(val) {
            if (val === 'library-character-map') {
                if (this.window.config.fastLoadMode) {
                    this.reveal()
                } else {
                    this.reveal()
                }
            }
        }
    },
    methods: {
        reveal() {
            setTimeout(this.reveal2,10)
        },
        reveal2() {
            this.mouseItem = this.document.getElementById("mobile-scaler-yesyesyall")

            let tl = gsap.timeline({
                onComplete:this.reveal3
            })
            let tme = 0.5
            let del = 0.25
            tl.fromTo("#library-character-map .items .item-center",tme,
                {
                    opacity:0,
                    scale:0,
                },
                {
                    scale:1,
                    duration: tme,
                    opacity:1,
                    ease: Power3.easeInOut
                },
                del
            )

            ////
            del += tme/2
            tl.fromTo("#library-character-map .lines .center svg path",
                {
                    drawSVG: "0%"
                },
                {
                    duration: tme*2,
                    drawSVG: "100%",
                    ease: Power3.easeOut
                },
                del
            )
            tl.fromTo("#library-character-map .lines .center svg line",
                {
                    drawSVG: "0%"
                },
                {
                    duration: tme*2,
                    drawSVG: "100%",
                    ease: Power3.easeOut
                },
                del
            )


            /////
            del += tme*1.5
            tl.fromTo("#library-character-map .lines .octogon svg path",
                {
                    drawSVG: "0%",
                    strokeDasharray: "5 10",
                },
                {
                    duration: tme*2,
                    drawSVG: "100%",
                    strokeDasharray: "5 10",
                    ease: Power3.easeOut
                },
                del
            )
            del += tme*2
            tl.set("#library-character-map .lines .octogon svg path",
                {
                    strokeDasharray: "5 10",
                },
                del
            )
            tl.fromTo("#library-character-map .lines .octtxt",
                {
                    opacity:0
                },
                {
                    duration:0.5,
                    opacity:1,
                    ease: Power3.easeInOut
                },
                del
            )

            del -= 1
            tl.fromTo("#library-character-map .items .item-the-library",tme,
                {
                    opacity:0,
                    scale:0,
                },
                {
                    scale:1,
                    duration: tme,
                    opacity:1,
                    ease: Power3.easeInOut
                },
                del
            )



            ///
            this.staggerNames(0.5, '.stagger1')
            this.staggerNames(1, '.stagger2')
            this.staggerNames(1, '.stagger3')
            this.staggerNames(1, '.stagger4')



        },
        staggerNames(del, hook) {
            let tl = gsap.timeline({})
            let sel = "#library-character-map .items " + hook
            tl.staggerFromTo(sel, 0.5,
                {
                    opacity:0,
                    scale:0
                },
                {
                    delay:del,
                    opacity:1,
                    scale:1,
                    ease: Power3.easeInOut,
                    stagger:0.1
                },
                0
            )
        },
        reveal3() {

        },
        swipeHandler (e, eventMouse) {
            this.gh.swipeHandler(e, eventMouse, this.mouseItem, '#mobile-scaler-yesyesyall' )
        }
    }
}
</script>
