<template>
    <div v-show="show" id="preloader" class="scaler" data-scaler-w="1920" data-scaler-h="1080">
        <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/preloader.jpg') + ')'}"></div>
        <div class="bgimg">
            <video id="preloader-video" :Zposter="require('@/assets/video/preloader.jpg')" muted autoplay loop playsinline webkit-playsinline volume="0" oncanplay="this.muted=true">
                <source :src="require('@/assets/video/preloader.mp4')">
            </video>
        </div>
    </div>
</template>

<script>
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { mapState } from 'vuex'
export default {
    name: "Preloader",
    data() {
        return {
            window:window,
            document:document,
            preloaderVideo:null,
            show:true,
            makeVisible: true
        }
    },
    computed: {
        ...mapState({
            preloaderPreloaded: state => state.preloaderPreloaded,
            allPreloaded: state => state.allPreloaded,
        }),
    },
    watch: {
        preloaderPreloaded() {
            console.log('preloaderPreloaded')
            setTimeout(this.preloaderPreloaded2,100)
        },
        allPreloaded() {
            this.preloaderVideoUnreveal()
        }
    },
    mounted() {
        if (this.window.config.fastLoadMode) {
            this.preloaderVideoEnded2() //DEBUG
        }
    },
    methods: {
        preloaderPreloaded2() {
            this.makeVisible = true
            const vid = document.getElementById("preloader-video")
            if (vid) {
                vid.volume = 0
                vid.muted = true
                vid.play()
            }

        },
        preloaderVideoUnreveal() {

            let tl = gsap.timeline({
                //delay:(this.revealReady) ? 0 : 0.85
                delay:0
            })
            tl.fromTo('#preloader',
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    duration:1,
                    ease: Power3.easeInOut,
                    onComplete: this.preloaderVideoEnded2
                }
            )
        },
        preloaderVideoEnded2() {
            this.show=false
        }
    }
}
</script>
