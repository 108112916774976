<template>
    <section :class="['page page-main', (String(currentSubsection).includes('character')) ? 'scroll' : 'fixed']">
        <Enter></Enter>
        <WakingDreaming></WakingDreaming>
        <Library></Library>
        <LostTools></LostTools>
        <Interstitial></Interstitial>
        <RavenGuide></RavenGuide>
        <Menu></Menu>
        <Overlay></Overlay>
    </section>
</template>
<script>
import { mapState } from 'vuex'
//import gsap, { Power3 } from '@/assets/js/gsap/all'
//import YouTubeCustom from "@/components/blocks/YouTubeCustom"
import Enter from "@/components/subsections/Enter"
import WakingDreaming from "@/components/subsections/WakingDreaming"
import Library from "@/components/subsections/Library"
import LostTools from "@/components/subsections/LostTools"
import RavenGuide from "@/components/framework/RavenGuide"
import Menu from "@/components/framework/Menu"
import Overlay from "@/components/framework/Overlay"
import Interstitial from "@/components/subsections/Interstitial"
export default {
    name: "Main",
    components: {
        LostTools,
        WakingDreaming,
        RavenGuide,
        Library,
        Enter,
        Menu,
        Overlay,
        Interstitial
    },
    data () {
        return {
            window: window,
            document:document,
            json: null,
            inPanelTransition: false,

            dissolveTime: 1250, //don't change this, it needs to be linked to the css step speed
        }
    },
    mounted () {
        setTimeout(this.reveal,10)
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition,
            currentSubsection: state => state.currentSubsection,
        }),
    },
    methods: {
        reveal: function() {
            this.inPanelTransition = true
        },
        reveal2 () {

        },
    },

}
</script>
