<template>
    <div :class="`scaler-lr ${currentZone}`" :data-scaler-lr="(doLessIntensiveMobile || (!doLessIntensiveMobile && !this.canvasStarted)) ? '' : 'onlylr'" :data-scaler-lr-w="scalerW" :data-scaler-lr-h="scalerH" id="waking-dreaming-canvas-holder">
        <!--  -->
        <div v-if="doLessIntensiveMobile || (!doLessIntensiveMobile && !this.canvasStarted)" class="mobile-device-bgs abs100">
            <div class="abs100 bgholder waking">
                <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/mobile-waking.jpg') + ')'}"></div>
                <div class="bgimg">
                    <video :Zposter="require('@/assets/video/mobile-waking.jpg')" autoplay loop muted playsinline
                           webkit-playsinline>
                        <source :src="require('@/assets/video/mobile-waking.mp4')">
                    </video>
                </div>
            </div>
            <div class="abs100 bgholder dreaming">
                <div class="bgimg"
                     :style="{'background-image': 'url(' + require('@/assets/video/mobile-dreaming.jpg') + ')'}"></div>
                <div class="bgimg">
                    <video :Zposter="require('@/assets/video/mobile-dreaming.jpg')" autoplay loop muted playsinline
                           webkit-playsinline>
                        <source :src="require('@/assets/video/mobile-dreaming.mp4')">
                    </video>
                </div>
            </div>
        </div>

        <canvas v-if="!doLessIntensiveMobile" :class="(canvasStarted) ? 'opacity:1' : 'opacity0'" :width="scalerW" :height="scalerH" id="waking-dreaming-canvas-waking"></canvas>
        <canvas v-if="!doLessIntensiveMobile" :class="(canvasStarted) ? 'opacity:1' : 'opacity0'" :width="scalerW" :height="scalerH" id="waking-dreaming-canvas-dreaming"></canvas>

    </div>
    <div class="special-tt">
        <div class="special-tt2">
            <img :src="require('@/assets/images/global/tt.png')" />
        </div>
    </div>
    <div :class="['special-swipe', (showMobileSwipe) ? '' : 'gone']">
        <div class="special-swipe2">
            <img :src="require('@/assets/images/waking-dreaming/icon-swipe.png')" />
        </div>
    </div>

    <div id="waking-dreaming-scaler" :class="`scaler-lr ${currentZone}`" :data-scaler-lr-w="scalerW" :data-scaler-lr-h="scalerH">
        <div v-touch:swipe="swipeHandler" v-touch:tap="tapHandler" @click="toggleZone" class="toggle-zone-hook"></div>
        <div @mouseenter="setMouseOverIcon" @mouseleave="setMouseOffIcon" :class="['icon-zone', (showIcons) ? 'show' : 'hide']" id="icon-zone-dreaming">
            <div @click="gh.newSectionStart('library-character-detail-dream')"
                 class="icon icon-dream">
                <div class="bg"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-dream.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-episodes')"
                 class="icon icon-episodes-dreaming">
                <div class="bg"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-chapters.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-character-detail-lucifer')" class="icon icon-lucifer">
                <div class="bg"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-lucifer.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-landing')" class="icon icon-library">
                <div class="bg"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-library.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-character-detail-lucienne')" class="icon icon-lucienne">
                <div class="bg"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-lucienne.png')"/>
            </div>
        </div>
        <div @mouseenter="setMouseOverIcon" @mouseleave="setMouseOffIcon" :class="['icon-zone', (showIcons) ? 'show' : 'hide']" id="icon-zone-waking">
            <div @click="gh.newSectionStart('library-episodes')" class="icon icon-episodes-waking">
                <div class="bg warm"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-chapters.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-character-detail-constantine')" class="icon icon-constantine">
                <div class="bg warm"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-constantine.png')"/>
            </div>
            <div @click="gh.openSpotify" class="icon icon-dreamcast">
                <div class="bg warm"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-dreamcast.png')"/>
            </div>
            <div @click="gh.newSectionStart('losttools')" class="icon icon-losttools">
                <div class="bg warm"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-losttools.png')"/>
            </div>
            <div @click="gh.newSectionStart('library-character-detail-rose')" class="icon icon-rose">
                <div class="bg warm"></div>
                <img :src="require('@/assets/images/waking-dreaming/icon-rose.png')"/>
            </div>
        </div>
    </div>
</template>
<script>
import {isMobile, isMobileOnly, isIOS} from 'mobile-device-detect'
import gh from '@/helpers/gh'
import {mapState} from 'vuex'

export default {
    name: "WakingDreamingContent",
    data () {
        return {
            document: document,
            window: window,
            gh: gh,

            mouseXBase: null,
            mouseY: null,
            mouseOverIcon: false,
            mouseItem: null,
            toggleZoneTransition: 0,//0 normal, 1 grow big, 2 grow small
            wakingDreamRekeyToggler: Math.random(),

            revealedOnce: false,
            showIcons: true,

            showMobileSwipe:true,

            doLessIntensiveMobile: true,

            canvasStarted:false,
        }
    },
    props: {
        scalerW: {
            type: Number,
            required: true,
        },
        scalerH: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            wakingDreaming: state => state.wakingDreaming,
            extraMouseX: state => state.extraMouseX,
            isMobileWidth: state => state.isMobileWidth,
        }),
        currentZone () {
            return (this.wakingDreaming === 'waking') ? 'waking' : 'dreaming'
        },
        mouseX () {
            //let ox = this.calcExtraMouseX()
            if (this.extraMouseX === 'end') {
                let ox = this.calcExtraMouseX()
                return this.mouseXBase + ox
            } else {
                let ox = parseFloat(this.mouseItem.getAttribute('data-overage-x'))
                //return this.mouseXBase - ox + extraXAdjust
                return this.mouseXBase + (ox + this.extraMouseX)
            }
        }

    },
    mounted () {
        //add a starting position for any situation where it might not have appeared
        this.mouseXBase = 100
        this.mouseY = 150

        //set to true to give up on the mask canvas for mobile
        if (isMobileOnly && isIOS) { //only IOS and MOBILE (not tablet)
            this.doLessIntensiveMobile = false
        } else {
            this.doLessIntensiveMobile = false
        }
        setTimeout(this.maskInit, 10)
    },
    beforeUnmount () {
        if (this.mouseItem) {
            this.mouseItem.removeEventListener('mousemove', this.mouseMove)
        }
    },
    methods: {
        tapHandler () {
            if (this.doLessIntensiveMobile || isMobile) {
                this.toggleZoneTransition = 1
                this.showIcons = false
                setTimeout(this.toggleZone2, (this.doLessIntensiveMobile) ? 500 : 1500)
            }
        },
        toggleZone () {
            // ignore mobile device - this is on tapHandler
            if (!isMobile && !this.doLessIntensiveMobile) {
                if (this.toggleZoneTransition === 0) {
                    this.showIcons = false
                    this.toggleZoneTransition = 1 //grow big
                    setTimeout(this.toggleZone2, 900) //this is the time it takes after big to grow small again
                }
            }
        },
        toggleZone2 () {
            this.toggleZoneTransition = 2 //grow from small to big
            this.showIcons = true
            if (this.currentZone === 'dreaming') {
                this.$store.commit('setWakingDreaming', 'waking')
                gh.pingAnalyticsCustom('waking-dreaming-toggle', 'waking')
            } else {
                this.$store.commit('setWakingDreaming', 'dreaming')
                gh.pingAnalyticsCustom('waking-dreaming-toggle', 'dreaming')
            }
        },


        //THE MASK
        getMouse (e, elem) {
            var element = elem,
                offsetX = 0,
                offsetY = 0,
                mx, my;
            // Compute the total offset. It's possible to cache this if you want
            if (element.offsetParent !== undefined) {
                do {
                    offsetX += element.offsetLeft;
                    offsetY += element.offsetTop;
                } while ((element = element.offsetParent));
            }
            mx = e.pageX - offsetX;
            my = e.pageY - offsetY;
            // We return a simple javascript object with x and y defined
            return {
                x: mx,
                y: my
            };
        },
        setMouseOverIcon () {
            this.mouseOverIcon = true
        },
        setMouseOffIcon () {
            this.mouseOverIcon = false
        },
        touchMoving(e) {
            this.mouseMove(e)
        },
        mouseMove (e) {
            // MOST COMPLICATED MATH PROBLEM IN ALL OF HISTORY
            let finalX = e.x
            this.mouseXBase = finalX
            this.mouseY = e.y

            this.showMobileSwipe=false
        },
        calcExtraMouseX () {
            let currentMouseSideOfScreen = this.mouseItem.getAttribute('data-currentMouseSideOfScreen')
            let ox = parseFloat(this.mouseItem.getAttribute('data-overage-x'))
            if (currentMouseSideOfScreen === 'left') {
                //ox = ox
                ox = 0
            } else if (currentMouseSideOfScreen === 'right') {
                ox = ox * 2
            }
            return ox
        },
        maskInit () {
            // complicated mask init
            this.mouseItem = this.document.getElementById("waking-dreaming-scaler")
            if (!this.doLessIntensiveMobile) {
                this.mouseItem.addEventListener('mousemove', this.mouseMove)
                this.setUpCanvas()
            }
        },
        setUpCanvas: async function () {
            this.gh.setTriggerResize()

            const scalerLR = document.getElementById('waking-dreaming-scaler')
            const canvasWaking = document.getElementById('waking-dreaming-canvas-waking')
            const canvasContextWaking = canvasWaking.getContext('2d')
            const canvasDreaming = document.getElementById('waking-dreaming-canvas-dreaming')
            const canvasContextDreaming = canvasDreaming.getContext('2d')

            let pngMaskSpriteJson = window.config.json.global.site.pngMaskSprite
            const maskImgSequenceTotal = pngMaskSpriteJson.totalSprites
            const rootName = pngMaskSpriteJson.rootName
            let maskImgSequenceCount = 0
            let maskImgSequence = []
            let imageW = 434
            let imageH = 368
            for (let i = 0; i <= maskImgSequenceTotal; i++) {
                maskImgSequence[i] = new Image()
                let dig = i
                if (i < 10) {
                    dig = '00' + i
                } else if (i < 100) {
                    dig = '0' + i
                }
                //maskImgSequence[i].src = require('@/assets/images/mask/seq/')
                maskImgSequence[i].src = require('@/assets/images/mask/seq/' + rootName + dig + '.png')
            }

            //add video 1
            const videoWaking = document.createElement('video')
            videoWaking.muted = true
            videoWaking.volume = 0
            videoWaking.autoplay = true
            videoWaking.loop = true
            videoWaking.playsInline = true
            videoWaking['webkit-playsInline'] = true
            if (this.isMobileWidth) {
                videoWaking.autoplay = false
                videoWaking.src = require('@/assets/video/mobile-waking.mp4')
            } else {
                videoWaking.src = require('@/assets/video/waking.mp4')
            }
            await videoWaking.play()

            //add video 2
            const videoDreaming = document.createElement('video')
            videoDreaming.muted = true
            videoDreaming.volume = 0
            videoDreaming.autoplay = true
            videoDreaming.loop = true
            videoDreaming.playsInline = true
            videoDreaming['webkit-playsInline'] = true
            if (this.isMobileWidth) {
                videoDreaming.autoplay = false
                videoDreaming.src = require('@/assets/video/mobile-dreaming.mp4')
            } else {
                videoDreaming.src = require('@/assets/video/dreaming.mp4')
            }
            await videoDreaming.play()

            //const maskImg = new Image()
            //maskImg.src = require('@/assets/images/mask/test.png')
            //maskImg.src = require('@/assets/images/mask/blob.svg')
            //maskImg.src = require('@/assets/images/mask/test.png')



            //recursive frame draw
            let t = this
            let mouseScaleSpeedReset = 0.1
            let mouseCurrentScaleSpeed = 0.1
            let mouseCurrentScale = 1
            let mouseMinimumScale = 0
            let mouseTargetScale = 1
            let mouseScaleBuffer = 0.11
            let isPulsing = false
            let globalAlpha = 1

            //set canvas as having started - resizes bc safari mobile is lame
            t.canvasStarted = true
            this.gh.setTriggerResize()
            setTimeout(this.gh.setTriggerResize,10)
            setTimeout(this.gh.setTriggerResize,1000)
            requestAnimationFrame(function frame () {
                const canvasWidth = parseInt(scalerLR.getAttribute('data-neww'))
                const canvasHeight = parseInt(scalerLR.getAttribute('data-newh'))
                let canvasScale = parseFloat(scalerLR.getAttribute('data-current-scale'))


                //clear
                canvasWaking.width = canvasWaking.width + 0
                canvasDreaming.width = canvasDreaming.width + 0


                //video 1
                //https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation


                // set size of mask, while calculating hovers
                if (t.toggleZoneTransition !== 0) {
                    if (t.toggleZoneTransition === 1) { //grow norm to 10
                        mouseTargetScale = (isMobile) ? 25 : 15
                        mouseCurrentScaleSpeed = 0.4
                    } else if (t.toggleZoneTransition === 2) { //grow from 0 to nrom
                        mouseTargetScale = 1
                        mouseCurrentScale = 0
                        mouseCurrentScaleSpeed = 0.1
                        t.toggleZoneTransition = 0
                    }
                } else if (t.mouseOverIcon) {
                    globalAlpha = 1
                    mouseTargetScale = mouseMinimumScale
                    isPulsing = false
                } else {
                    mouseTargetScale = 1
                    if (!isPulsing) {
                        /*
                        let rnd = Math.floor(Math.random() * 100) //1 in 200 chance of pulsing
                        if (rnd===0) {
                            isPulsing = true
                            //let pls = (Math.random() * 2)-1
                            mouseTargetScale = 1.1
                            mouseCurrentScaleSpeed = 0.1
                        } else {
                            mouseTargetScale = 1
                        }
                         */
                    }
                }
                if (t.mouseX === null && !isMobile) {
                    mouseCurrentScale = 0
                }



                if (mouseCurrentScale > mouseTargetScale + mouseScaleBuffer) {
                    mouseCurrentScale -= mouseCurrentScaleSpeed
                } else if (mouseCurrentScale < mouseTargetScale - mouseScaleBuffer) {
                    mouseCurrentScale += mouseCurrentScaleSpeed
                } else {
                    mouseCurrentScale = mouseTargetScale
                    isPulsing = false
                    mouseCurrentScaleSpeed = mouseScaleSpeedReset
                    globalAlpha = 1
                }
                let newMouseFrameW = imageW * mouseCurrentScale * canvasScale
                let newMouseFrameH = imageH * mouseCurrentScale * canvasScale
                //shrink this down a touch more for mobile
                if (isMobile) {
                    newMouseFrameW *= 0.6
                    newMouseFrameH *= 0.6
                }
                if (newMouseFrameW < 1) {
                    newMouseFrameW = 1
                    newMouseFrameH = 1
                }
                let newMouseX = t.mouseX - (newMouseFrameW / 2)
                let newMouseY = t.mouseY - (newMouseFrameH / 2)

                if (t.currentZone === 'waking') {
                    canvasContextWaking.drawImage(videoWaking, 0, 0, videoWaking.videoWidth, videoWaking.videoHeight, 0, 0, canvasWidth, canvasHeight)
                    canvasContextDreaming.drawImage(videoDreaming, 0, 0, videoDreaming.videoWidth, videoDreaming.videoHeight, 0, 0, canvasWidth, canvasHeight)

                    //png mask
                    //canvasContextDreaming.globalCompositeOperation = 'destination-in';
                    //canvasContextDreaming.drawImage(maskImg, 0, 0, 500, 500, t.mouseX-250, t.mouseY-250, 500, 500);

                    //video mask
                    //canvasContextDreaming.globalCompositeOperation = composite
                    //canvasContextDreaming.drawImage(maskImg, 0, 0, videoW, videoH, t.mouseX-(videoW/2), t.mouseY-(videoH/2), videoW, videoH);

                    //png sequence mask
                    canvasContextDreaming.globalCompositeOperation = 'destination-in'
                    canvasContextDreaming.globalAlpha = globalAlpha
                    canvasContextDreaming.drawImage(maskImgSequence[maskImgSequenceCount], 0, 0, imageW, imageH, newMouseX, newMouseY, newMouseFrameW, newMouseFrameW)
                    if (t.mouseOverIcon) {
                        canvasContextDreaming.globalCompositeOperation = 'hue' //hue
                        canvasContextDreaming.globalAlpha = globalAlpha
                        canvasContextDreaming.drawImage(maskImgSequence[maskImgSequenceCount], 0, 0, imageW, imageH, newMouseX, newMouseY, newMouseFrameW, newMouseFrameW)
                    }
                } else {
                    canvasContextDreaming.drawImage(videoDreaming, 0, 0, videoDreaming.videoWidth, videoDreaming.videoHeight, 0, 0, canvasWidth, canvasHeight)
                    canvasContextWaking.drawImage(videoWaking, 0, 0, videoWaking.videoWidth, videoWaking.videoHeight, 0, 0, canvasWidth, canvasHeight)

                    //png mask
                    //canvasContextWaking.globalCompositeOperation = 'destination-in';
                    //canvasContextWaking.drawImage(maskImg, 0, 0, 500, 500, t.mouseX-250, t.mouseY-250, 500, 500);

                    //video mask
                    //canvasContextWaking.globalCompositeOperation = 'screen'
                    //canvasContextWaking.drawImage(maskImg, 0, 0, videoW, videoH, t.mouseX-(videoW/2), t.mouseY-(videoH/2), videoW, videoH);
                    //canvasContextWaking.globalCompositeOperation = 'destination-in';
                    //canvasContextWaking.drawImage(maskImg, 0, 0, videoW, videoH, t.mouseX-(videoW/2), t.mouseY-(videoH/2), videoW, videoH);


                    //png sequence mask
                    canvasContextWaking.globalCompositeOperation = 'destination-in'
                    canvasContextWaking.globalAlpha = globalAlpha
                    canvasContextWaking.drawImage(maskImgSequence[maskImgSequenceCount], 0, 0, imageW, imageH, newMouseX, newMouseY, newMouseFrameW, newMouseFrameW)

                    if (t.mouseOverIcon) {
                        canvasContextWaking.globalCompositeOperation = 'hue' //hue, source-out
                        canvasContextWaking.globalAlpha = globalAlpha
                        canvasContextWaking.drawImage(maskImgSequence[maskImgSequenceCount], 0, 0, imageW, imageH, newMouseX, newMouseY, newMouseFrameW, newMouseFrameW)
                    }

                }

                maskImgSequenceCount++
                if (maskImgSequenceCount > maskImgSequenceTotal) {
                    maskImgSequenceCount = 0
                }

                requestAnimationFrame(frame)
            }.bind(t))

        },


        swipeHandler (e, eventMouse) {
            this.showMobileSwipe=false
            this.gh.swipeHandler(e, eventMouse, this.mouseItem, '#waking-dreaming .scaler-lr' )
        }
    } //methods
}
</script>
