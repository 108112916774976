<template>
    <div id="container" :class="[routeName, routeType]">
        <div id="main">
            <router-view></router-view>
        </div>
        <Footer :isMenu="false" :key="$route.fullPath"></Footer>
        <Intro></Intro>
        <Preloader></Preloader>
        <RotateBlocker v-show="showBlocker"></RotateBlocker>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
//import TransitionOverlay from './components/framework/TransitionOverlay'
import Footer from './components/framework/Footer'
import Intro from './components/framework/Intro'
import Preloader from './components/framework/Preloader'
import RotateBlocker from './components/framework/RotateBlocker'
import {isMobile, browserName} from 'mobile-device-detect'
import Preload from 'preload-it';
import router from '@/router'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { mapState } from 'vuex'
export default {
    name: 'App',
    components: {
        RotateBlocker,
        Footer,
        Intro,
        Preloader
        //TransitionOverlay
    },
    data () {
        return {
            window: window,
            document: document,
            showBlocker: false,
            didInitialReveal: false,
            preloaderReady: false,

            currentMouseSideOfScreen: 'center'
        }
    },
    mounted () {
        gsap.config({
            nullTargetWarn: false,
        })

        // https://www.npmjs.com/package/mobile-device-detect
        const browser = String(browserName).toLowerCase()
        this.document.body.className += ' browser-' + browser
        if (isMobile) {
            this.document.body.className += ' device-mobile'
        } else {
            this.document.body.className += ' device-notmobile'
        }

        this.window.addEventListener('resize', this.resize)
        this.resize()

        if (isMobile) {
            this.window.addEventListener('orientationchange', this.siteOrientationChange)
            this.siteOrientationChange()
        } else {
            this.window.addEventListener('mousemove', this.mouseMove)
        }

        setTimeout(this.preloadThePreloader, 200)
    },
    computed: {
        ...mapState({
            currentSubsectionNew: state => state.currentSubsectionNew,
            triggerResize: state => state.triggerResize,
        }),
        routeName () {
            const path = gh.stripSlashesFrontAndBack(router.currentRoute.value.path)
            return `route-${path}`
        },
        routeType () {
            const type = gh.stripSlashesFrontAndBack(router.currentRoute.value.meta.type)
            return `route-type-${type}`
        }
    },
    watch: {
        currentSubsectionNew () {
            this.resize()
        },
        triggerResize () {
            this.resize()
        }
    },
    methods: {
        preloadThePreloader () {
            let preload = Preload()
            console.log('preloadThePreloader')
            let preloadAssets = [
                require('@/assets/video/preloader.mp4')
                //'/assets/site/video/preloader.mp4'
            ]
            preload.oncomplete = items => {
                console.log('pre-preloader', items);
                this.preloadThePreloaderComplete()
            }

            preload.onprogress = event => {
                console.log('pre-preloader', event.progress + '%');
            }

            preload.onfetched = item => {
                console.log('pre-preloader', item);
            }

            preload.onerror = item => {
                console.log('pre-preloader error', item);
                //this.preloadThePreloaderFail()
            }
            preload.fetch(preloadAssets).then(items => {
                // use either a promise or 'oncomplete'
                console.log('pre-preloader promise', items)
            });
        },
        preloadThePreloaderComplete () {
            console.log('preloadThePreloaderComplete')
            this.resize()
            this.preloaderReady = true
            this.$store.commit('setPreloaderPreloaded')
            setTimeout(this.regularPreload, 10)
        },
        regularPreload () {
            console.log('regularPreload')
            this.resize()
            //preload
            let preloadAssets = [
                //'/assets/site/images/cross-fade/sprite2.png',
                require('@/assets/video/interstitial.jpg') //just do cover image
            ]

            if (isMobile) {
                //intro
                preloadAssets.push(require('@/assets/video/mobile-opening.mp4'))
                preloadAssets.push(require('@/assets/video/mobile-opening.jpg'))
                preloadAssets.push(require('@/assets/video/mobile-loop.mp4'))
                preloadAssets.push(require('@/assets/video/mobile-loop.jpg'))

                preloadAssets.push(require('@/assets/video/mobile-waking.mp4'))
                preloadAssets.push(require('@/assets/video/mobile-dreaming.mp4'))
            } else {
                //intro
                preloadAssets.push(require('@/assets/video/opening.mp4'))
                preloadAssets.push(require('@/assets/video/opening.jpg'))
                preloadAssets.push(require('@/assets/video/opening-loop.mp4'))
                preloadAssets.push(require('@/assets/video/opening-loop.jpg'))

                preloadAssets.push(require('@/assets/video/waking.mp4'))
                preloadAssets.push(require('@/assets/video/dreaming.mp4'))
            }
            let preload = Preload()
            preload.oncomplete = items => {
                console.log('preload complete', items)
                this.regularPreloadComplete()
            }

            preload.onprogress = event => {
                console.log('preload', event.progress + '%')
            }

            preload.onfetched = item => {
                console.log('preload', item)
            }

            preload.onerror = item => {
                console.log('preload error', item)
            }
            preload.fetch(preloadAssets).then(items => {
                // use either a promise or 'oncomplete'
                console.log('preload promise', items)
            });
        },
        regularPreloadComplete () {
            this.resize()
            this.$store.commit('setAllPreloaded')
            setTimeout(this.reveal, 10)
            this.doPostLoad()
        },

        doPostLoad () {
            console.log('doPostLoad')
            let preload = Preload()
            let postloadAssets = []

            //sprites
            let pngMaskSpriteJson = window.config.json.global.site.pngMaskSprite
            const maskImgSequenceTotal = pngMaskSpriteJson.totalSprites
            const rootName = pngMaskSpriteJson.rootName
            for (let i=0;i<=maskImgSequenceTotal;i++) {
                let dig = i
                if (i<10) {
                    dig = '00'+i
                } else if (i<100) {
                    dig = '0'+i
                }
                postloadAssets.push(require('@/assets/images/mask/seq/' + rootName + dig + '.png'))
            }
            if (isMobile) {
                postloadAssets = []
                postloadAssets.push(require('@/assets/video/mobile-intro-library.jpg'))
                postloadAssets.push(require('@/assets/video/mobile-intro-library.mp4'))
                postloadAssets.push(require('@/assets/video/mobile-intro-tools.jpg'))
                postloadAssets.push(require('@/assets/video/mobile-intro-tools.mp4'))
                postloadAssets.push(require('@/assets/video/mobile-bg-library.jpg'))
                postloadAssets.push(require('@/assets/video/mobile-bg-library.mp4'))
                postloadAssets.push(require('@/assets/video/mobile-bg-library.jpg'))
                postloadAssets.push(require('@/assets/video/mobile-bg-smokey.mp4'))
                postloadAssets.push(require('@/assets/video/mobile-bg-tools.jpg'))
                postloadAssets.push(require('@/assets/video/mobile-bg-tools.mp4'))

                postloadAssets.push(require('@/assets/images/menu/bg-mobile.jpg'))
            } else {
                postloadAssets.push(require('@/assets/video/intro-library.jpg'))
                postloadAssets.push(require('@/assets/video/intro-library.mp4'))
                postloadAssets.push(require('@/assets/video/intro-tools.jpg'))
                postloadAssets.push(require('@/assets/video/intro-tools.mp4'))
                postloadAssets.push(require('@/assets/video/bg-library.jpg'))
                postloadAssets.push(require('@/assets/video/bg-library.mp4'))
                postloadAssets.push(require('@/assets/video/bg-library.jpg'))
                postloadAssets.push(require('@/assets/video/bg-smokey.mp4'))
                postloadAssets.push(require('@/assets/video/bg-tools.jpg'))
                postloadAssets.push(require('@/assets/video/bg-tools.mp4'))

                postloadAssets.push(require('@/assets/images/menu/bg.jpg'))
            }

            preload.fetch(postloadAssets).then(items => {
                // use either a promise or 'oncomplete'
                console.log('postload promise', items)
            })
        },


        /// REVEAL
        reveal () {
            this.didInitialReveal = true
            this.resize()
            this.$store.commit('youTubeClickable', true)
        },

        resize () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = this.window.innerHeight * 0.01;
            this.document.documentElement.style.setProperty('--vh', `${vh}px`)



            this.siteOrientationChange()


            let w = this.window.innerWidth
            let h = this.window.innerHeight

            //store is mobile detected via width for use in other components
            this.$store.commit('setIsMobileWidth', ( w <= 850) )

            //scaler
            let itemsScaler = this.document.getElementsByClassName("scaler")
            for(let i = 0; i < itemsScaler.length; i++) {
                let item = itemsScaler[i]
                let scalerW = parseInt(item.getAttribute('data-scaler-w'))
                let scalerH = parseInt(item.getAttribute('data-scaler-h'))
                //let orientTC = parseInt(item.getAttribute('data-scaler-orientTC'))

                let scalerRat = scalerW / scalerH

                let newW = w
                let newH = w / scalerRat
                let scale = newW / scalerW
                if (newH < h) {
                    newH = h
                    newW = h * scalerRat
                    scale = newH / scalerH
                }
                let newX = (newW - w) / 2
                let newY = (newH - h) / 2
                /*
                if (orientTC===1) {
                    newY = 0
                }

                 */
                gsap.set(item, {
                    scale: scale,
                    width: scalerW,
                    height: scalerH,
                    x: -newX,
                    y: -newY
                })
                item.setAttribute('data-neww', scalerW)
                item.setAttribute('data-newh', scalerH)
            }


            //scaler lr
            let items = this.document.getElementsByClassName("scaler-lr")
            for(let i = 0; i < items.length; i++) {
                let item = items[i]



                let scalerW = parseInt(item.getAttribute('data-scaler-lr-w'))
                let scalerH = parseInt(item.getAttribute('data-scaler-lr-h'))
                let scalerRat = scalerW / scalerH

                // serious messed up browser behavior - scales different when canvas is above 100% w or height than below
                // so this concept was added to try to fix it
                let onlyLR = (item.getAttribute('data-scaler-lr') === 'onlylr')
                if (onlyLR && h>scalerH) {
                    onlyLR = false
                }


                // set to height
                let newH = h
                let newW = newH * scalerRat
                let scale = newH / scalerH
                if (newW < w) { //if not wide enough
                    newW = w
                    newH = newW / scalerRat
                    scale = newW / scalerW
                }
                let newX = (newW - w) / 2
                let newY = (newH - h) / 2

                // some items needed nudged left or right because they were created off center but need to look centered
                let nudgeX = parseInt(item.getAttribute('data-nudge-x'))
                if (!nudgeX) {
                    nudgeX = 0
                } else {
                    nudgeX *= scale
                }
                newX += nudgeX

                //console.log('onlyLR', onlyLR)
                gsap.set(item, {
                    scale: (onlyLR) ? 1 : scale,
                    //scale:scale,
                    left: -newX,
                    top: (onlyLR) ? 0 : -newY,
                })
                item.setAttribute('data-overage-x', newX)
                item.setAttribute('data-current-scale', scale)
                item.setAttribute('data-neww', newW)
                item.setAttribute('data-newh', newH)
                item.setAttribute('data-overage-newX', newX)
                item.setAttribute('data-currentMouseSideOfScreen', this.currentMouseSideOfScreen)
            }


            //scaler-contained
            let itemsScalerContained = this.document.getElementsByClassName("scaler-contained")
            for(let i = 0; i < itemsScalerContained.length; i++) {
                let item = itemsScalerContained[i]
                let scalerW = parseInt(item.getAttribute('data-scaler-w'))
                let scalerH = parseInt(item.getAttribute('data-scaler-h'))
                let extraPaddingX = parseInt(item.getAttribute('data-extra-padding-x'))
                let extraPaddingY = parseInt(item.getAttribute('data-extra-padding-y'))
                let scalerRat = scalerW / scalerH

                extraPaddingX += 100 //also indent a little because scaler-contained *almost* always have back buttons
                let paddingX = 150
                let paddingY = 50
                let wAdj = w - paddingX - extraPaddingX
                let hAdj = h - paddingY - extraPaddingY
                let newW = wAdj
                let newH = wAdj / scalerRat
                let scale = newW / scalerW
                if (newH > hAdj) {
                    newH = hAdj
                    newW = hAdj * scalerRat
                    scale = newH / scalerH
                }
                let newX = (wAdj - newW) / 2
                let newY = (hAdj - newH) / 2
                newX += (paddingX/2)+extraPaddingX
                newY += (paddingY/2)+extraPaddingY
                gsap.set(item, {
                    scale: scale,
                    width: scalerW,
                    height: scalerH,
                    x: newX,
                    y: newY
                })
            }

            // readjust any scaler lr items by resetting to center and calculating
            this.currentMouseSideOfScreen = 'center'
            this.mouseMoveShiftScalerLR()

        },
        siteOrientationChange () {
            if (isMobile) {
                let t = this
                setTimeout(function () {
                    console.log(t.window.innerWidth, t.window.innerHeight)
                    t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
                    //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
                }.bind(t), 100)
            }
        },

        mouseMove(e) {

            //for scaler-lr elements, move to left and right side of screem
            let w = this.window.innerWidth
            let pctScreen = e.clientX / w

            let mouseSideOfScreen = 'center'
            let leftPct = 0.15
            let rightPct = 0.85
            if (this.currentMouseSideOfScreen !== 'center') {
                leftPct = 0.4
                rightPct = 0.6
            }
            if (pctScreen <= leftPct) {
                mouseSideOfScreen = 'left'
            } else if (pctScreen >= rightPct) {
                mouseSideOfScreen = 'right'
            }
            if (mouseSideOfScreen !== this.currentMouseSideOfScreen) {
                this.currentMouseSideOfScreen = mouseSideOfScreen
                this.mouseMoveShiftScalerLR()
            }
        },
        mouseMoveShiftScalerLR() {
            var items = this.document.getElementsByClassName("scaler-lr")
            for(let i = 0; i < items.length; i++) {
                let item = items[i]
                let overageX = Math.floor(parseInt(item.getAttribute('data-overage-x')))
                let newX = 0
                if (this.currentMouseSideOfScreen === 'left') {
                    newX = overageX
                } else if (this.currentMouseSideOfScreen === 'right') {
                    newX = -overageX
                }
                let t = this
                gsap.to(item, {
                    x: newX,
                    duration: 1,
                    ease:Power3.easeInOut,

                    onUpdate:function() {
                        let x = gsap.getProperty(item, "x")
                        t.$store.commit('setExtraMouseX', -x)
                    }.bind(t),
                    onUpdateParams:[item],

                    onComplete: function() {
                        t.$store.commit('setExtraMouseX', 'end')
                    }.bind(t),
                    onCompleteParams:[item],
                })
                item.setAttribute('data-overage-newX', newX)
                item.setAttribute('data-currentMouseSideOfScreen', this.currentMouseSideOfScreen)
            }
        },
    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
