<template>
    <div v-if="(currentLibrarySubsection==='library-episodes')" id="library-episodes" :class="['library-subsection', (currentLibrarySubsection==='library-episodes') ? 'visible' : '']">
        <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/bg-smokey.jpg') + ')'}"></div>
        <div class="bgimg">
            <video :Zposter="require('@/assets/video/bg-smokey.jpg')" autoplay loop muted playsinline webkit-playsinline>
                <source :src="require('@/assets/video/bg-smokey.mp4')">
            </video>
        </div>
        <EpisodeSlideShow :loadMobile="isMobileWidth" :key="slideShowRekey"></EpisodeSlideShow>

        <BackButton target="library-landing" copy="The Library"></BackButton>

    </div>
</template>

<script>
import EpisodeSlideShow from "@/components/subsections/episodes/EpisodeSlideShow"
import {mapState} from 'vuex'
import BackButton from "@/components/blocks/BackButton"
import gh from '@/helpers/gh'
export default {
    name: "LibraryEpisodes",
    data () {
        return {
            document: document,
            window: window,
            gh: gh,

            slideShowRekey:Math.random
        }
    },
    components: {
        BackButton,
        EpisodeSlideShow
    },
    computed: {
        ...mapState({
            currentLibrarySubsection: state => state.currentLibrarySubsection,
            isMobileWidth: state => state.isMobileWidth,
        }),
    },
    watch: {
        //rekey on changing section and also changing width
        currentLibrarySubsection (val) {
            if (val === 'library-episodes') {
                this.slideShowRekey = Math.random()
            }
        },
        isMobileWidth () {
            this.slideShowRekey = Math.random()
        }
    },
    methods: {

    }
}
</script>
