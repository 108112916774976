<template>
    <footer :class="['footer', (isMenu) ? 'menu' : 'site', (hideFooter) ? 'opacity0' : 'opacity1']">
        <div class="line">
            <div class="holder">
                <div class="bottom">
                    <div class="center">
                        <div class="center2">
                            <div class="social-icon-holder0">
                                <div class="social-icon-holder">
                                    <div class="social-icon twitter">
                                        <a href="https://twitter.com/netflix_sandman" target="_blank">
                                            <inline-svg :src="require('@/assets/images/global/icon-twitter.svg')" />
                                        </a>
                                    </div>
                                    <div class="social-icon instagram">
                                        <a href="https://www.instagram.com/thesandmanofficial/" target="_blank">
                                            <inline-svg :src="require('@/assets/images/global/icon-instagram.svg')" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="logo">
                                <a href="https://www.netflix.com/title/81150303" target="_blank">
                                    <img class="full" :src="require('@/assets/images/global/logo.svg')" />
                                </a>
                            </div>
                            <div class="legal">
                                <span class="txt">© 2022 NETFLIX. ALL RIGHTS RESERVED</span>
                                <span class="bar">|</span>
                                <a href="/privacy-policy" target="_blank">
                                    <span class="link">Privacy & Terms</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "Footer",
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew
        }),
        hideFooter() {
            if (this.isMenu) {
                return false
            } else {
                return (this.currentSubsection || this.currentSubsectionNew)
            }
        }
    },
    props: {
        isMenu: {
            type: Boolean,
            required: true,
        },
    }
}
</script>
