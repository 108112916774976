<template>
    <div v-show="show" id="intro" >
        <div class="intro-holder intro-holder-desktop show-desktop">
            <div class="scaler" data-scaler-w="1920" data-scaler-h="1080">
                <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/opening.jpg') + ')'}"></div>
                <div class="bgimg">
                    <video id="intro-video" :Zposter="require('@/assets/video/opening.jpg')" muted playsinline webkit-playsinline>
                        <source :src="require('@/assets/video/opening.mp4')">
                    </video>
                </div>
            </div>
        </div>
        <div class="intro-holder intro-holder-mobile show-mobile">
            <div class="scaler" data-scaler-w="619" data-scaler-h="1080">
                <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/mobile-opening.jpg') + ')'}"></div>
                <div class="bgimg ">
                    <video id="intro-video-mobile" :Zposter="require('@/assets/video/mobile-opening.jpg')" muted playsinline webkit-playsinline>
                        <source :src="require('@/assets/video/mobile-opening.mp4')">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { mapState } from 'vuex'
export default {
    name: "Intro",
    data() {
        return {
            window:window,
            document:document,
            show:true
        }
    },
    computed: {
        ...mapState({
            allPreloaded: state => state.allPreloaded,
        }),
    },
    watch: {
        allPreloaded() {
            this.show = true
            console.log('allPreloaded')
            setTimeout(this.mountVideo,50)
        }
    },
    methods: {
        mountVideo() {
            let w = this.window.innerWidth
            let introVideo = null
            if (w<=850) {
                introVideo = this.document.getElementById("intro-video-mobile")
            } else {
                introVideo = this.document.getElementById("intro-video")
            }
            introVideo.addEventListener("ended", this.introVideoEnded)
            introVideo.muted = true
            introVideo.play()
            if (this.window.config.fastLoadMode || this.window.config.fastLoadSkipIntro) {
                this.introVideoEnded2() //DEBUG
            }
        },
        introVideoEnded() {
            //mobile play
            let mobileLoop  = document.getElementById("mobile-loop")
            if (mobileLoop) {
                mobileLoop.muted = true
                mobileLoop.play()
            }
            let openingLoop  = document.getElementById("opening-loop")
            if (openingLoop) {
                openingLoop.muted = true
                openingLoop.play()
            }
            let tl = gsap.timeline({
                //delay:(this.revealReady) ? 0 : 0.85
                delay:0
            })
            tl.fromTo('#intro',
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    duration:1,
                    ease: Power3.easeInOut,
                    onComplete: this.introVideoEnded2
                }
            )

        },
        introVideoEnded2() {
            this.show=false
        }
    }
}
</script>
