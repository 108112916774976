<template>
    <section v-if="json" class="page page-generic" >
        <div class="panel">
            <div class="top">
                <div class="top2">
                    <div class="logo">
                        <img class="full" :src="require('@/assets/images/global/logo.svg')" />
                    </div>
                    <div class="close">
                        <router-link to="/">
                            <div class="close-button"></div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="rte no-max-width" v-html="json.body"></div>
            </div>
        </div>

    </section>
</template>
<script>
import axios from 'axios'
import router from '@/router'
//import Parallax from 'parallax-js'
import gh from '@/helpers/gh'
export default {
    name: "Generic",
    components: {

    },
    data () {
        return {
            window: window,
            json: null,
            gh:gh,
            inPanelTransition: false,
        }
    },
    created () {

    },
    mounted () {
        let path = gh.stripSlashesFrontAndBack(router.currentRoute.value.path)
        let obj = gh.getRouteObjectFromVal(path,true)
        if (!obj) {
            path = 'not-found'
        }
        const url = `${this.window.config.apiroot}${path}${this.window.config.apiext}`
        axios.get(url)
            .then((res) => {
                this.json = res.data
                setTimeout(this.reveal,10)
            }).catch(() => {
        })
    },
    methods: {
        reveal: async function() {

        },
    }
}
</script>
