<template>
    <div v-show="gh.showSection('interstitial')" id="interstitial" :class="['subsection', gh.subsectionClasses('interstitial')]">
        <div class="interstitial-holder interstitial-holder-desktop show-desktop">
            <div class="scaler" data-scaler-w="1920" data-scaler-h="1080">
                <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/interstitial.jpg') + ')'}"></div>
                <div class="bgimg">
                    <video id="interstitial-video" :poster="require('@/assets/video/interstitial.jpg')" muted playsinline webkit-playsinline>
                        <source :src="require('@/assets/video/interstitial.mp4')">
                    </video>
                </div>
            </div>
        </div>
        <div class="interstitial-holder interstitial-holder-mobile show-mobile">
            <div class="scaler" data-scaler-w="619" data-scaler-h="1080">
                <div class="bgimg" :style="{'background-image': 'url(' + require('@/assets/video/interstitial.jpg') + ')'}"></div>
                <div class="bgimg ">
                    <video id="interstitial-video-mobile" :poster="require('@/assets/video/interstitial.jpg')" muted playsinline webkit-playsinline>
                        <source :src="require('@/assets/video/interstitial.mp4')">
                    </video>
                </div>
            </div>
        </div>
        <div  :class="['btn-skip', (showSkip) ? 'show' : '']">
            <div  class="btn-skip2">
                <div class="bg"></div>
                <img @click="clickSkip" :src="require('@/assets/images/global/btn-skip.png')" />
            </div>
        </div>
    </div>
</template>

<script>
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
export default {
    name: "Interstitial",
    data() {
        return {
            window:window,
            document:document,
            gh:gh,
            show:false,

            interstitialVideo:null,
            showSkip:false
        }
    },
    computed: {
        ...mapState({
            currentSubsection: state => state.currentSubsection,
            currentSubsectionNew: state => state.currentSubsectionNew,
            globalVolume: state => state.globalVolume,
        })
    },
    watch: {
        globalVolume() {
            if (this.interstitialVideo) {
                this.interstitialVideo.muted = (this.globalVolume === 0)
            }
        },
        currentSubsectionNew (val) {
            if (val === 'interstitial') {
                if (this.window.config.fastLoadMode) {
                    this.interstitialVideoEnded() //reinit to skip video, reveal to see video
                } else {
                    this.show = true
                    setTimeout(this.mountVideo,10)
                }
            }
        },
    },
    methods: {
        mountVideo() {
            //fade in
            let tl = gsap.timeline({})
            tl.fromTo('#interstitial', 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0
            )

            let w = this.window.innerWidth
            this.interstitialVideo = null
            if (w<=850) {
                this.interstitialVideo = this.document.getElementById("interstitial-video-mobile")
            } else {
                this.interstitialVideo = this.document.getElementById("interstitial-video")
            }
            this.interstitialVideo.addEventListener("ended", this.interstitialVideoEnded)
            this.interstitialVideo.muted = (this.globalVolume === 0)
            this.interstitialVideo.play()

            this.gh.setTriggerResize()

            setTimeout(this.showSkipCallback,2500)
        },
        showSkipCallback() {
            this.showSkip = true
        },


        interstitialVideoEnded() {
            if (this.interstitialVideo) {
                this.interstitialVideo.removeEventListener("ended", this.interstitialVideoEnded)
                this.interstitialVideo.pause()
            }
            this.gh.setCompetingAudioPlaying(false)
            this.gh.interstitialEnded()

            let tl = gsap.timeline({
                delay:0
            })
            tl.fromTo('#interstitial', 0.5,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    ease: Power3.easeInOut,
                    onComplete: this.interstitialVideoEnded2
                }
            )
            //setTimeout(this.interstitialVideoEnded2,500)
        },
        interstitialVideoEnded2() {
            this.show=false
        },

        clickSkip() {
            this.interstitialVideoEnded()
        }
    }
}
</script>
