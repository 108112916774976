<template>
    <div :class="`copy-outer ${extraClasses}`">
        <div v-if="popupCharacter==='cainabel'" class="copy cainabel">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    CAIN &amp; ABEL
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">The first victim and the first</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-cainabel`" class="part2">
                <div :id="`part22-cainabel`" class="part22">
                    <div class="copy-description stagger">
                        murderer
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">residents and loyal subjects of the</div>
                    </div>
                    <div class="copy-description stagger">
                        dream realm.
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='constantine'" class="copy constantine">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    JOHANNA<br>CONSTANTINE
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar big stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-big-left.png')"/>
                    <div class="copy-description">TOUGH</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-big-right.png')"/>
                </div>
            </div>
            <div :id="`part2-constantine`" class="part2">
                <div :id="`part22-constantine`" class="part22">
                    <div class="copy-description stagger">
                        brilliant,<br>tricky,
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">haunted necromancer &<br>successful occult adventuress<br>for hire.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='corinthian'" class="copy corinthian">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    The<br>CORINTHIAN
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">An escaped</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-corinthian`" class="part2">
                <div :id="`part22-corinthian`" class="part22">
                    <div class="copy-description stagger">
                        nightmare
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">who wishes to taste all that</div>
                    </div>
                    <div class="copy-description stagger">
                        The world
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">has in store.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='death'" class="copy death">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    DEATH
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Dream’s</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-death`" class="part2">
                <div :id="`part22-death`" class="part22">
                    <div class="copy-description stagger">
                        wiser,<br>nicer,
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">& much more</div>
                    </div>
                    <div class="copy-description stagger">
                        sensible
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">sister in the<br>Endless family.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='desire'" class="copy desire">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    DESIRE
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Dream's fellow Endless sibling,</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-desire`" class="part2">
                <div :id="`part22-desire`" class="part22">
                    <div class="copy-sidebar stagger">
                        <div class="txt">and the embodiment of</div>
                    </div>
                    <div class="copy-description stagger">
                        everything
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">you want</div>
                    </div>
                    <div class="copy-description stagger">
                        whatever
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">you want</div>
                    </div>
                    <div class="copy-description stagger">
                        & whoever
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">you are.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='despair'" class="copy despair">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    DESPAIR
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Desire's twin, and Dream's sister.</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-despair`" class="part2">
                <div :id="`part22-despair`" class="part22">
                    <div class="copy-sidebar stagger">
                        <div class="txt">She is the moment when</div>
                    </div>
                    <div class="copy-description stagger">
                        all hope<br> is gone,
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">the bleakest<br>of the Endless.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='dream'" class="copy dream">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    THE SANDMAN,<br>MASTER OF DREAMS.
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Lord of the realm we all visit when</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-dream`" class="part2">
                <div :id="`part22-dream`" class="part22">
                    <div class="copy-description stagger">
                        we dream.
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">Dream is one member of a<br>larger family known as the</div>
                    </div>
                    <div class="copy-description stagger">
                        endless
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">immortal beings who<br>rule their own realms.
                        </div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='ethel'" class="copy ethel">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    ETHEL CRIPPS
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Roderick Burgess’s</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-ethel`" class="part2">
                <div :id="`part22-ethel`" class="part22">
                    <div class="copy-description stagger">
                        love in the<br>1920<span>s</span> & 30<span>s</span>
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">and John Dee’s mother, who plays<br>a vital role in Dream’s fate.</div>
                    </div>
                    <!-- <div class="copy-description stagger">
                        dream realm.
                    </div> -->
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='gilbert'" class="copy gilbert">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    GILBERT
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Rose Walker’s</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-gilbert`" class="part2">
                <div :id="`part22-gilbert`" class="part22">
                    <div class="copy-description stagger">
                        debonair<br>protector.
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">A dab hand with a</div>
                    </div>
                    <div class="copy-description stagger">
                        paradox
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">and a sword cane.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='john'" class="copy john">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    JOHN DEE
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Ethel’s son</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-john`" class="part2">
                <div :id="`part22-john`" class="part22">
                    <div class="copy-description stagger">
                        driven mad<br>long ago,
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">is on a quest for truth<br>that may destroy the world.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='lucienne'" class="copy lucienne">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    LUCIENNE
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">The chief</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-lucienne`" class="part2">
                <div :id="`part22-lucienne`" class="part22">
                    <div class="copy-description stagger">
                        librarian<br>& trusted<br>guardian
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">of Dream’s realm.</div>
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='lucifer'" class="copy lucifer">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    LUCIFER
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">The ruler of Hell</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-lucifer`" class="part2">
                <div :id="`part22-lucifer`" class="part22">
                    <div class="copy-description stagger">
                        elegant<br>beautiful<br>& cunning.
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='lyta'" class="copy lyta">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    LYTA HALL
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Rose’s friend,</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-lyta`" class="part2">
                <div :id="`part22-lyta`" class="part22">
                    <div class="copy-description stagger">
                        a young<br>widow
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">mourning her husband.</div>
                    </div>
                    <!-- <div class="copy-description stagger">
                        dream realm.
                    </div> -->
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='raven'" class="copy raven">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    MATTHEW<br>THE RAVEN
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">Dream’s</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-raven`" class="part2">
                <div :id="`part22-raven`" class="part22">
                    <div class="copy-description stagger">
                        trusted<br>& talkative,<br>emissary.
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='roderick'" class="copy roderick">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    RODERICK BURGESS
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">A charlatan,</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-roderick`" class="part2">
                <div :id="`part22-roderick`" class="part22">
                    <div class="copy-description stagger">
                        blackmailer,<br>& magician
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">who tries to entrap Death, but<br>accidentally captures Death’s brother,<br>Dream, instead.
                        </div>
                    </div>
                    <div class="copy-description stagger">
                        dream realm.
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='rose'" class="copy rose">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    ROSE WALKER
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">A young woman on a</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-rose`" class="part2">
                <div :id="`part22-rose`" class="part22">
                    <div class="copy-description stagger">
                        desperate
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">search for her missing brother,<br>who finds a family she didn't know<br>that she had,and a
                            connection to Dream<br>that neither of them can
                        </div>
                    </div>
                    <div class="copy-description stagger">
                        escape.
                    </div>
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
        <div v-if="popupCharacter==='unity'" class="copy unity">
            <div class="copy-top">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-top.png')"/>
                </div>
            </div>
            <div class="part1">
                <div class="copy-name stagger">
                    UNITY KINCAID
                </div>
                <div class="copy-miniline stagger"></div>
                <div class="copy-sidebar stagger">
                    <img class="left" :src="require('@/assets/images/character-details/copy-sidebar-left.png')"/>
                    <div class="txt">An heiress, and Rose’s</div>
                    <img class="right" :src="require('@/assets/images/character-details/copy-sidebar-right.png')"/>
                </div>
            </div>
            <div :id="`part2-unity`" class="part2">
                <div :id="`part22-unity`" class="part22">
                    <div class="copy-description stagger">
                        mysterious<br>benefactor
                    </div>
                    <div class="copy-sidebar stagger">
                        <div class="txt">She has spent a century asleep. Now she’s<br>awake, having missed out on her life.</div>
                    </div>
                    <!-- <div class="copy-description stagger">
                        dream realm.
                    </div> -->
                </div>
            </div>
            <div class="copy-bottom">
                <div class="inner">
                    <img :src="require('@/assets/images/character-details/copy-bottom.png')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CharacterDiamonds",
    props: {
        popupCharacter: {
            type: String,
            required: false,
        },
        extraClasses: {
            type: String,
            required: false,
            default: "",
        },
    }
}
</script>
