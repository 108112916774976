//import createjs from 'preload-js'
import gsap, { Power1 } from '@/assets/js/gsap/all'
const sinAnimate = {
    iDistance: 50,
    speed: 5,

    init(sel, section) {
        gsap.killTweensOf(sel)
        let interval = 0
        let targets = gsap.utils.toArray(sel)
        if (targets.length) {
            interval = 360/(targets.length-1) //180 = start with a half sin

            let distanceBefore = -1
            for (let i = 0;i < targets.length;i++) {
                let isX = false
                if (section === 'library-landing-mobile' || section === 'library-character-names-mobile') {
                    isX = true
                }

                let target = targets[i]
                let intervalSiner = i * interval
                let startingXorY = Math.sin(intervalSiner) * sinAnimate.iDistance
                let del = i*(sinAnimate.speed/targets.length)
                let isDivider = (target.className.indexOf('divider')>-1)


                //resets and sets
                let obj = {
                    opacity:0,
                }
                if (isX) {
                    obj.x = startingXorY
                } else {
                    let topReset = this.getTopReset(target,section)
                    obj.top = topReset
                    obj.y = startingXorY

                }
                if (isDivider) {
                    obj.scaleY = 0
                }
                gsap.set(target, obj)

                //fast fade in
                gsap.to(target, 1,
                    {
                        opacity: 1,
                        scaleY:1,
                        delay: i * 0.1,
                        ease:Power1.easeInOut
                    }
                )

                let direction = null
                if (startingXorY<0) {
                    direction = -1
                } else if (startingXorY > 0) {
                    direction = -1
                } else {
                    direction = distanceBefore
                }
                distanceBefore = direction


                if (direction===1) {
                    setTimeout(function() {
                        sinAnimate.sinAnimateGoingUp(target, isX)
                    }.bind(target),del*1000)
                } else {
                    setTimeout(function() {
                        sinAnimate.sinAnimateGoingDown(target, isX)
                    }.bind(target),del*1000)
                }
            }
        }
    },

    //KARL - I reset all your hard earned CSS - yay - i do this so i can start with perfect sinwave
    getTopReset(target, section) {
        let topReset = 0
        if (section==='library-character-names') {
            topReset = 230
            if (target.className.indexOf('twoline')>-1) {
                topReset -= 55
            } else if (target.className.indexOf('divider')>-1) {
                topReset -= 100
            } else if (target.className.indexOf('item-title')>-1) {
                topReset += 120
            }
        } else if (section==='library-landing') {
            topReset = 210
            if (target.className.indexOf('divider')>-1) {
                topReset -= 100
            } else if (target.className.indexOf('item-the-library')>-1) {
                topReset += 110
            } else if (target.className.indexOf('item')>-1) {
                topReset += 320
            }
        }

        return topReset
    },
    sinAnimateGoingUp(target, isX) {
        let obj = {
            ease: Power1.easeInOut,
            onComplete:sinAnimate.sinAnimateGoingDown,
            onCompleteParams:[target, isX]
        }
        if (isX) {
            obj.x = sinAnimate.iDistance
        } else {
            obj.y = sinAnimate.iDistance
        }
        gsap.to(target, sinAnimate.speed,obj)
    },
    sinAnimateGoingDown(target, isX) {
        let obj = {
            ease: Power1.easeInOut,
            onComplete:sinAnimate.sinAnimateGoingUp,
            onCompleteParams:[target, isX]
        }
        if (isX) {
            obj.x = -sinAnimate.iDistance
        } else {
            obj.y = -sinAnimate.iDistance
        }
        gsap.to(target, sinAnimate.speed,obj)
    }
}
export default sinAnimate
