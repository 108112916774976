<template>
    <div v-show="isOpen" id="overlay">
        <div v-if="this.extra === 'spotify'" class="show-desktop bgimg" :style="{'background-image': 'url(' + firstImagePath + ')'}"></div>
        <div id="overlay-center-hook" :class="['inner', (isOpenAndReady) ? 'ready' : '']">
            <div class="close-catcher abs100" @click="closeAction"></div>
            <div id="overlay-center-hook2" class="inner2">
                <div class="swiper" id="overlay-swiper-hook">
                    <div class="swiper-wrapper">
                        <div v-for="(item, index) in overlaySlides" :class="`swiper-slide swiper-slide-${index}`" :key="`swiper-slide-${index}`">
                            <div class="bgimg" :style="{'background-image': 'url(' + item.imagePath + ')'}"></div>
                            <div v-if="this.extra === 'spotify'" class="spotify-wrapper abs100">
                                <iframe class="spotify-iframe" v-if="this.extra==='spotify'" style="border-radius:12px" src="https://open.spotify.com/embed/episode/4jkYVn5LVaqUWjY6AReESD?utm_source=generator&theme=0" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                            </div>
                            <div v-if="this.extra !== 'spotify' && item.videoPath" class="bgimg">
                                <video id="overlay-video" :Zposter="item.imagePath" controls muted playsinline webkit-playsinline>
                                    <source :src="item.videoPath">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div @click="closeAction" class="close-button">
            <inline-svg :src="require('@/assets/images/global/icon-close.svg')" />
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import gsap, { Power3 } from '@/assets/js/gsap/all'
import { mapState } from 'vuex'
export default {
    name: "Overlay",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            firstImagePath:'',
            hasVideo: false,
            extra: '',

            assetWidth:0,
            assetHeight:0,

            isOpen:false,
            isOpenAndReady:false,

            vid:null,

            overlaySlides: [],
        }
    },
    computed: {
        ...mapState({
            overlayObject: state => state.overlayObject,
        }),
    },
    mounted() {
        this.window.addEventListener('resize', this.onResize)
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    watch: {
        overlayObject: {
            deep: true,

            // We have to move our method to a handler field
            handler(obj) {
                this.firstImagePath = null
                this.hasVideo = false
                this.extra = null
                if (obj) {
                    if (Array.isArray(obj)) {
                        this.overlaySlides = obj
                        for (let i=0;i<this.overlaySlides.length;i++) {
                            if (this.overlaySlides[i].videoPath) {
                                this.hasVideo = true
                            }
                        }
                    } else {
                        this.overlaySlides = [ obj ]
                        this.firstImagePath = obj.imagePath
                        this.hasVideo = (obj.videoPath)
                        this.extra = obj.extra
                    }
                    this.reveal()
                } else {
                    this.unreveal()
                }

            }
        },
    },
    methods: {
        reveal() {
            //get image meta info and reveal
            const t = this
            this.isOpenAndReady = false
            const img = new Image()
            img.onload = function() {
                this.isOpenAndReady = true
                if (img.width && img.height) {
                    t.assetWidth = img.width
                    t.assetHeight = img.height
                } else {
                    t.assetWidth = 0
                    t.assetHeight = 0
                }
                t.onResize()
            }.bind(t)
            img.src = this.firstImagePath

            //load in overlay
            let tl = gsap.timeline({
                onComplete:this.reveal2
            })
            tl.fromTo('#overlay', 0.5,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                0
            )

            this.isOpen=true
        },
        reveal2() {
            if (this.overlaySlides) {
                this.vid = document.getElementById("overlay-video")
                if (this.vid) {
                    this.vid.pause()
                    this.vid.addEventListener("ended", this.videoEnded)
                    this.vid.volume = 1
                    this.vid.muted = false
                    this.vid.currentTime=0
                    this.vid.play()
                }
            }
        },
        unreveal() {
            let tl = gsap.timeline({
                onComplete:this.unreveal2
            })
            tl.to('#overlay', 0.5,
                {
                    opacity:0,
                    ease: Power3.easeInOut
                },
                0
            )
            if (this.vid) {
                this.vid.pause()
                this.vid.removeEventListener("ended", this.videoEnded)
            }
        },
        unreveal2() {
            this.isOpen=false
            this.isOpenAndReady=false
            this.firstImagePath = ''
            this.hasVideo = false
            this.extra = ''
            this.assetWidth = 0
            this.assetHeight = 0
            this.vid = null
            this.overlaySlides = []
        },

        closeAction() {
            gh.closeOverlay()
        },
        videoEnded() {
            if (this.vid) {
                this.vid.pause()
            }
        },


        escapeButtonPressed(e) {
            if (this.isOpen) {
                if (e.key === "Escape") {
                    this.closeAction()
                }
            }
        },
        onResize() {
            if (this.isOpenAndReady) {
                if (this.assetWidth>0 && this.assetHeight>0) {
                    //always center within boundary box and keep aspect ratio
                    let elem = document.getElementById("overlay-center-hook")
                    let w = elem.clientWidth
                    let h = elem.clientHeight
                    let rat = this.assetWidth/this.assetHeight
                    let newW = w
                    let newH = newW/rat
                    if (newH > h) {
                        newH = h
                        newW = newH*rat
                    }
                    let newX = (w-newW)/2
                    let newY = (h-newH)/2
                    gsap.set('#overlay-center-hook2',
                        {
                            left:newX,
                            top:newY,
                            width:newW,
                            height:newH,
                        }
                    )
                } else {
                    //handler if we dont have aspect ratio
                    gsap.set('#overlay-center-hook2',
                        {
                            left:0,
                            top:0,
                            width:'100%',
                            height:'100%',
                        }
                    )
                }
            }//isOpenAndReady
        },//onresize



    },
}
</script>
