<template>
    <div :class="[`character-content ${characterName}`, (lockSmokeBg) ? 'isBelow' : 'isAbove', (part2TextIsUnlocked) ? 'part2-unlocked' : '']" >
        <div class="above-fold">
            <div class="show-mobile scaler" :data-scaler-w="1080" :data-scaler-h="940">
                <div class="bgimg" :style="{'background-image': 'url(' + require(`@/assets/images/character-details/detail/${characterName}/mobile-hero.jpg`) + ')'}"></div>
                <div class="bgimg">
                    <video :Zposter="require(`@/assets/images/character-details/detail/${characterName}/mobile-hero.jpg`)" autoplay loop muted playsinline webkit-playsinline>
                        <source :src="require(`@/assets/images/character-details/detail/${characterName}/mobile-hero.mp4`)">
                    </video>
                </div>
            </div>
            <div class="show-desktop scaler" :data-scaler-w="heroWidth" :data-scaler-h="heroHeight">
                <div class="bgimg" :style="{'background-image': 'url(' + require(`@/assets/images/character-details/detail/${characterName}/hero.jpg`) + ')'}"></div>
                <div class="bgimg">
                    <video :Zposter="require(`@/assets/images/character-details/detail/${characterName}/hero.jpg`)" autoplay loop muted playsinline webkit-playsinline>
                        <source :src="require(`@/assets/images/character-details/detail/${characterName}/hero.mp4`)">
                    </video>
                </div>
            </div>
        </div>
        <div id="smokebg" :class="['smokebg', (lockSmokeBg) ? 'locked' : '']">
            <div class="show-mobile bgimg" :style="{'background-image': 'url(' + require(`@/assets/video/mobile-bg-smokey.jpg`) + ')'}"></div>
            <div class="show-mobile bgimg">
                <video :Zposter="require('@/assets/video/mobile-bg-smokey.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/mobile-bg-smokey.mp4')">
                </video>
            </div>
            <div class="show-desktop bgimg" :style="{'background-image': 'url(' + require(`@/assets/video/bg-smokey.jpg`) + ')'}"></div>
            <div class="show-desktop bgimg">
                <video :Zposter="require('@/assets/video/bg-smokey.jpg')" autoplay loop muted playsinline webkit-playsinline>
                    <source :src="require('@/assets/video/bg-smokey.mp4')">
                </video>
            </div>
        </div>
        <div :class="[`below-fold`, (lockSmokeBg) ? 'locked' : '']">
            <div :id="`scrollies-${characterName}`" :class="`scrollies  ${blurbSide}`">
                <CharacterDiamonds :extraClasses="`${blurbSide}`" :rekey="rekey" :popupCharacter="characterName"></CharacterDiamonds>
                <div v-for="(item, index) in scrollies" :class="[`item item-${index}`, (item.videoSrc) ? 'video' : 'image']"  :key="`scrolly-${index}`">
                    <img @click="clickScrolly(item)" :src="require(`@/assets/images/character-details/detail/${characterName}/${item.imageSrc}`)" />
                    <img v-if="item.videoSrc" class="icon" :src="require('@/assets/images/global/circle-play.svg')"/>
                    <img v-else class="icon" :src="require('@/assets/images/global/search-plus.svg')"/>
                </div>
            </div>
        </div>
        <CharacterDiamonds :extraClasses="`show-desktop ${blurbSide}`" :rekey="rekey" :popupCharacter="characterName"></CharacterDiamonds>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import scrollyRolly from '@/helpers/scrollyRolly'

import { mapState } from 'vuex'
//import gsap, { Power3 } from '@/assets/js/gsap/all'
import CharacterDiamonds from "@/components/subsections/character/CharacterDiamonds"
import gsap, { Power3 } from '@/assets/js/gsap/all'
export default {
    name: "CharacterDetail",
    data() {
        return {
            document: document,
            window: window,
            gh: gh,

            heroWidth:1920,
            heroHeight:1080,
            blurbSide: 'right',
            scrollies:[],

            lockSmokeBg:false,
            part2TextIsUnlocked:false,
        }
    },
    props: {
        characterName: {
            type: String,
            required: true,
        },
        rekey: {
            type: Number,
            required: true,
        }
    },
    components: {
        CharacterDiamonds,
    },
    mounted() {
        for(let i=0;i<this.window.config.json.global.site.characters.length;i++) {
            let obj = this.window.config.json.global.site.characters[i]
            if (obj.name === this.characterName) {
                this.scrollies = obj.images
                this.blurbSide = obj.blurbSide
                this.heroWidth = (obj.heroWidth) ? obj.heroWidth : 1920
                this.heroHeight = (obj.heroHeight) ? obj.heroHeight : 1080
            }
        }

        //let elem = this.document.getElementById('#characters .character-detail')
        //elem.addEventListener('scroll', this.onScroll)
    },
    beforeUnmount() {
        scrollyRolly.destroy()
    },
    computed: {
        ...mapState({
            currentCharacterSubsection: state => state.currentCharacterSubsection,
            isMobileWidth: state => state.isMobileWidth,
        }),
    },
    watch: {
        currentCharacterSubsection() {
            this.initOnScrollHooks()
            setTimeout(this.reveal,10)
        }
    },
    methods: {
        reveal() {
            //REVEAL TOP
            let tl = gsap.timeline({
                onComplete:this.revealDoneJiggleNameSafariHack
            })
            let del = 1
            let sel = ".character-content .copy." + this.characterName + " .part1"
            this.revealTextParts(tl, del, sel)

            //final
            del += 0.25
            sel = ".character-content .copy." + this.characterName + " .copy-top"
            tl.fromTo(sel, 0.35,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                del
            )
            sel = ".character-content .copy." + this.characterName + " .copy-bottom"
            tl.fromTo(sel, 0.35,
                {
                    opacity:0,
                },
                {
                    opacity:1,
                    ease: Power3.easeInOut
                },
                del
            )
        },
        revealDoneJiggleNameSafariHack() {
            // yes, this is stupid


        },
        revealPart2() {
            this.part2TextIsUnlocked = true
            let tl = gsap.timeline({})
            let del = 0


            //height reveal
            const elem = this.document.getElementById('part22-'+this.characterName)
            let h = elem.clientHeight + 5
            tl.fromTo('#part2-'+this.characterName, 0.5,
                {
                    height:0,
                },
                {
                    height:h,
                    ease: Power3.easeInOut,
                    onComplete:this.revealPart2b
                },
                del
            )

            let sel = ".character-content .copy." + this.characterName + " .part2"
            this.revealTextParts(tl, del, sel)
        },
        revealPart2b() {
            gsap.set('#part2-'+this.characterName, {
                className:"part2 doneHeightReveal",
                height:'auto'
            },0)
        },
        revealTextParts(tl, del, sel) {

            let targets = gsap.utils.toArray(sel)
            //let targets = this.document.querySelectorAll(sel)
            for (let i = 0;i < targets.length;i++) {
                let target = targets[i]
                del += 0.03
                tl.fromTo(target, 0.35,
                    {
                        opacity:0,
                        scaleX:0.5,
                    },
                    {
                        opacity:1,
                        scaleX:1,
                        ease: Power3.easeInOut
                    },
                    del
                )
            }
        },


        initOnScrollHooks() {
            let sel = '#scrollies-'+this.characterName  + ' .item'
            let elemSmokeBg = this.document.querySelector('#smokebg')
            scrollyRolly.init(sel, elemSmokeBg, this)
        },

        clickScrolly(item) {
            let imageSrc = require(`@/assets/images/character-details/detail/${this.characterName}/${item.imageSrc}`)
            if (item.videoSrc) {
                let videoSrc = require(`@/assets/images/character-details/detail/${this.characterName}/${item.videoSrc}`)
                gh.openOverlay(imageSrc, videoSrc)
            } else {
                gh.openOverlay(imageSrc)
            }
        }

    }
}
</script>
